import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

class StudyAtHomeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };
  }

  shouldShowModal = () =>{
    if(this.state.isOpen == false) return false;

    if(document.cookie.match(/^(.*;)?\s*study_at_home_modalb\s*=\s*[^;]+(.*)?$/)) return false
    return true;
  }

  closeModal = () => {
    var exdays = 1;
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = "study_at_home_modalb=set;" + expires + ";path=/";
    this.setState({isOpen: false});
  }

  isPreReqPurchased = (cid) => {
    for(var i=0;i<this.props.account.prereqs.length;i++) {
      if(this.props.account.prereqs[i].custom_id == cid) {
        return this.props.account.prereqs[i].purchased;
      }
    }
  }

  isPreReqComplete = (name) => {
    for(var i=0;i<this.props.account.prereqs.length;i++) {
      if(this.props.account.prereqs[i].name == name) {
        return this.props.account.prereqs[i].is_complete;
      }
    }
  }

  delayReminder = () => {
    var exdays = 1;
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = "study_at_home_modalb=set;" + expires + ";path=/";
    this.setState({isOpen: false});
  }


  render() {
    if(this.shouldShowModal()) {
      return(
        <div>
          <div className="modal-overlay" onClick={this.closeModal}></div>
          <div className="modal-content" id="welcome-modal">
            <a href="#close" className="close-modal" onClick={this.closeModal}><FaTimes/></a>
            <div className="inner-content">
              <h1>NEW - LIMITED TIME ONLY</h1>
              <p>
              We know that social distancing is having a major impact on everyone and want to help in any way we can. For a limited time, we have unlocked all of our written level tests so you can complete them while you are at home. You do not need to pay at this time.
              </p>
              <p>
              Your grades for each test will be saved and will transfer to your credentialing dashboard once you pay for them. When you are ready to complete your credentials, you will be required to purchase the level courses and your progress will be imported. In addition to the written level tests you will still need to complete the following: 
              </p>
              <p>
              PCA ($30 fee)
              </p>
              <p>
              Darkness to Light ($20 fee)
              </p>
              <p>
              Practical Test with an instructor – if this is your first or second time taking that specific level test ($25 fee)
              </p>
              <p>
              You will still be required to complete all components of the credentialing process in order to be considered credentialed.  However, this allows you to start without paying for the level tests upfront.  PCA & Darkness to Light.
              </p>
              <div className="button-group">
              </div>
              <Button>
              <a style={{textDecoration:'none',color:"#fff"}}href="/study-at-home" onClick={this.delayReminder} className="reminder">Start Studying</a>
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return(
      <div></div>
    );
  }
}
function mapStateToProps(state) {
  const { account } = state;
  return {
    account: account,
  };
}

export default connect(mapStateToProps)(StudyAtHomeModal);
