import React from 'react';
import {Row, Column} from 'react-foundation';
import CredentialCard from '../credentialCard';
import {connect} from 'react-redux';
import Loading from '../Loading';
import {calculateLevel7Status} from '../../util/credentials';

class Credential extends React.Component {
    getCredentialedCourses = () => {
      var courses = [];

      for(const property in this.props.credentials) {
        if(this.props.credentials[property].max_level > 0) {
          courses.push(this.props.credentials[property]);
        }
      }

      // Level 7
      try {
        if(calculateLevel7Status(this.props.credentials)=='credentialed'){
          courses.push({
            'name'            : "Cheer Building Level 7",
            'group'           : "Level 7",
            'is_credentialed' : true,
            'max_level'       : 7,
            'in_progress'     : true,
            'expiration'      : this.props.credentials.building.credentials[6].expiration,
            'credentials'     : [this.props.credentials.building.credentials[6]]
          });
        }
      }
      catch(error){/*Silently fail*/};

      return courses;
    }

    render () {
        if(this.getCredentialedCourses().length == 0 && !this.props.isFetching)
          return(<span></span>);
        return (
          <div className="credential">
             <Row className="label-content-gap">
                <Column small={12} className="line-wrap">
                  <span className="horizontal-line-text">Your Credentials</span>
                  <div className="horizontal-line-background"></div>
                </Column>
              </Row>
              <Row>
                { this.props.isFetching &&
                  <Loading/>
                }
                {!this.props.isFetching && this.getCredentialedCourses().map( (course, index) => 
                  <CredentialCard
                    key={index}
                    course={course}
                    expanded={false}
                  />
                )}
              </Row>
          </div>
        )
    }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

export default connect(mapStateToProps)(Credential);
