import React, { Component } from 'react';

export default class EADance extends Component {
	constructor() {
		super();
		this.state = {
			activeIndex: 1
		}
	}

	render() {
		return (
			<div>				
				<span className="tab-contents">History will appear when you successfully credential in Cheer Tumbling.</span>
			</div>
		)
	}
}

