import React from 'react';
import {Row, Column} from 'react-foundation';
import { Link } from "react-router-dom";

class BottomArea extends React.Component {
    render () {
        return (
          <div className="bottom-area">
             <Row className="label-content-gap">
                <Column small={12}>
                  <div className="horizontal-line"></div>
                </Column>
              </Row>
              <Row>
                <Column small={6}>
                  <div>@ {new Date().getFullYear()} USASF All Rights Reserved</div>
                </Column>
                <Column small={6} className="footer-links text-right">
                  <a href="mailto:credentialing@usasf.net">Help Desk</a>
                  <Link to={"/terms-of-use"}>
                    Terms of Use
                  </Link>
                  <Link to={"/page/1907"}>
                    Privacy Policy
                  </Link>
                </Column>
              </Row>
          </div>
        )
    }
}

export default BottomArea;
