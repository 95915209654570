import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {Row, Column, Button} from 'react-foundation';
import {fetchScheduledPracticals} from '../../actions/practicalTest';
import Loading from '../Loading';
import ScheduledPracticals from './ScheduledPracticals';

class InstructorDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchScheduledPracticals();
  }

  getTodaysPracticals = () => {
    var now = new Date();
    var timestamp = Math.round(now.getTime()/1000);
    var twentyFourHours = 24*60*60;
    var tommorow = timestamp + twentyFourHours;

    return this.props.scheduledPracticals.filter( practical => {
      if(practical.date <= tommorow)
        return true
      else
        return false;
    });
  }

  getUpcomingPracticals = () => {
    var now = new Date();
    var timestamp = Math.round(now.getTime()/1000);
    var twentyFourHours = 24*60*60;
    var tommorow = timestamp + twentyFourHours;

    return this.props.scheduledPracticals.filter( practical => {
      if(practical.date > tommorow)
        return true
      else
        return false;
    });
  }

  render() {
    return(
      <div>
        <Row className="title-container">
          <Column large={6} medium={6} small={12}>
            <div className="title">Welcome back, {this.props.account.name.first}!</div>
          </Column>
        </Row>
        <Row>
          { this.props.isFetching &&
            <Loading/>
          }
          { !this.props.isFetching &&
            <div className="card" style={{width:'100%'}}>
              <Row>
                <Column small={12}>
                  <h1>Upcoming Tests</h1>
                  <ScheduledPracticals practicals={this.props.scheduledPracticals}/>
                </Column>
              </Row>
            </div>
          }
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {account, practicalTest} = state;
  return {
    account: account,
    isFetching: practicalTest.isFetching,
    scheduledPracticals: practicalTest.scheduledPracticals
  };
}
const mapDispatchToProps = {
  fetchScheduledPracticals
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructorDashboard);
