import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import {Row, Column} from 'react-foundation';
import { FaChevronRight} from 'react-icons/fa';
// import {
//   withRouter
//   } from 'react-router-dom'

import Credential from '../components/credential';
import ProgressCourses from '../components/progressCourses';
import AvailableCourses from '../components/availableCourses';
import PrerequisiteCourses from '../components/prerequisiteCourses';
import LockIcon from '../images/prereq-lock.jpg';
import AddToCartModal from '../components/modals/AddToCartModal';
import {getAvailablePracticals} from '../util/credentials';
import InstructorDashboard from '../components/instructor/InstructorDashboard';
import Instructions from '../components/Instructions';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  getCredentialedCourses = () => {
    var courses = [];
    for(var i=0;i<this.state.courses.length;i++) {
      if(this.state.courses[i].started) {
        if(typeof this.state.courses[i].levels !== 'undefined') {
          var isCredentialed = false;
          for(var j=0;j<this.state.courses[i].levels.length;j++) {
            if(this.state.courses[i].levels[j].completedDate != null) {
              isCredentialed = true;
              break;
            }
          }
          if(isCredentialed)
            courses.push(this.state.courses[i].id);
        }
      }
    }
    return courses;
  }

  allPreReqsCompleted = () => {
    for(var i=0;i<this.props.account.prereqs.length;i++) {
        if(this.props.account.prereqs[i].is_optional) continue;

        if(!this.props.account.prereqs[i].is_complete 
            && this.props.account.prereqs[i].id != "170" // glossary
            && this.props.account.prereqs[i].custom_id != "pca") 
        {
            return false;
        }
    }
    return true;
  }

  startCourse = (item) => {
    if(typeof item.custom_id !== 'undefined') {
      if(item.custom_id === 'd2l') {
        this.props.history.push('/darkness-to-light');
      }
      else if(['pca','safe-at-allstar'].includes(item.custom_id)) {
        this.props.history.push('/'+item.custom_id);
      }
      else {
        this.props.history.push('/written/'+item.id);
      }
    }
    else {
      this.props.history.push('/written/'+item.id);
    }
  }

  arePracticalsAvailable = () => {
    var availableCredentials = getAvailablePracticals(this.props.credentials);
    return availableCredentials.length > 0;
  }

  render() {
    if(this.props.account.role === 'instructor') {
      return (
        <InstructorDashboard/>
      )
    }
    return (
      <div>
        <Row className="title-container">
          <Column large={6} medium={6} small={12}>
            <div className="title">Welcome back, {this.props.account.name.first}!</div>
          </Column>
          <Column large={6} medium={6} small={12}>
            { this.arePracticalsAvailable() &&
              <Link to="/schedule-practical" className="button yellow-button-large">Schedule a Practical Session</Link>
            }
             { !this.arePracticalsAvailable() &&
              <a href="#" className="button yellow-button-large" disabled style={{backgroundColor:'lightgray'}}>Schedule a Practical Session</a>
            }
          </Column>
        </Row>

        {/*
        <Link to="/worlds-2020/">
          <div className="worlds-notice-wrap">
            <div className="worlds-notice">
                <div>
                  <img src="/app/themes/usasf-react/react-src/public//images/worlds-logo.png"/>
                </div>

                <a href="#">
                Worlds Cheerleading Requirements 2020 <FaChevronRight/>
                </a>
            </div>
          </div>
        </Link>
        */}

        <Instructions slug='overview-of-the-credentialing-process'/>

        { !this.allPreReqsCompleted() &&
          <div>
            <PrerequisiteCourses startCourse={this.startCourse}/>
            <div className="prereq-notice">
              <Row>
                <Column small={1}>
                </Column>
                <Column small={1}>
                  <img src={LockIcon} className="lock" alt="Lock"/>
                </Column>
                <Column small={9}>
                  <p>Baseline LMS Coach Course must be completed
                  before unlocking credentialing courses and past credentialing history.</p>
                </Column>
                <Column small={1}>
                </Column>
              </Row>
            </div>
            <div className="disabled-overlay">
              <div className="disable-clicks"></div>
              <AvailableCourses/>
            </div>
          </div>
        }

        {/*<Cards />*/}


        { this.allPreReqsCompleted() &&
          <div>
            <Credential />
            <ProgressCourses/>
            <AvailableCourses/>
            <PrerequisiteCourses startCourse={this.startCourse} />
          </div>
        }

        <AddToCartModal {...this.props}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    account: account,
    credentials: account.credentials
  };
}

export default connect(mapStateToProps)(Dashboard);
