import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import {connect} from 'react-redux';

import CollapsibleGroup from './CollapsibleGroup';
import tick from "../images/tick.png";

import { FaLock } from 'react-icons/fa';

import NextStepButton from './progressCard/NextStepButton';
import AvailablePracticals from './AvailablePracticals';

import {startCourse, formatMoney, formatTimestamp} from '../util/credentials';
import {addItemToCart} from '../actions/commerce';
import {withRouter} from 'react-router-dom';

class RequirementTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getCredentials = () => {
    if(this.props.course.credentials.length === 1 && this.props.filter == 'renewal')  {
        return this.props.course.credentials;
    }

    return this.props.course.credentials.filter((credential, index)=>{
      
      if(this.props.course.name != "Cheer Building Level 7"){
        if(index>=6) return false;
      }

      if(this.props.filter == 'renewal') {
        if(index >= this.props.course.max_level) return false;
      }
      if(this.props.filter == 'upgrade') {
        if(index < this.props.course.max_level) return false;
      }
      return true;
    });
  }

  isReqLocked = (index, subIndex) => {
    // We moved level 7 to a standalone course, which requires
    // special logic. #techdebt
    if(this.props.course.name == "Cheer Building Level 7") {
      if(subIndex == 0) {
        return false;
      }
      else {
        return !this.props.course.credentials[0].reqs[subIndex-1].completed;
      }
    }
    // Enforce renewing levels in order
    else if(this.props.filter === "renewal") {
      let lastUpdated = null;
      let nextIndex = 0;
      let incompletWrittenReached = false;
      let filteredCredentials = this.getCredentials();

      filteredCredentials.map( (credential, index) => {
        let writtenTest = null;

        // Get the completion date of the written requirement
        let writtenRequirements = credential.reqs.filter(r=>r.type==="written");
        if(writtenRequirements.length > 0) {
          writtenTest = writtenRequirements[0];
        }

        // Default to first course
        if(writtenTest !== null && lastUpdated === null) {
          lastUpdated = writtenTest;
        }

        if(!incompletWrittenReached) {
          // There was a tempory bug in the system that allowed users to skip
          // courses instead of completing them in order. To patch this, we
          // force the users to complete skipped courses in order.
          // If no courses have been skipped, find the most recently completed writtent
          // and unlock the next course.
          if(writtenTest !== null && writtenTest.completed_date === null) {
            incompletWrittenReached = true;
            nextIndex = index;
          }
          else if(writtenTest.completed_date >= lastUpdated.completed_date) {
            lastUpdated = writtenTest;
            nextIndex = (index+1) % filteredCredentials.length;
          }
        }
      });

      if(index != nextIndex){
        return true;
      }
    }
    else {
      var lastWrittenCompleted = 0;

      this.getCredentials().map((credential)=> {
        if(typeof credential.reqs !== 'undefined') {
          var reqs = credential.reqs.filter( (req)=>{ return req.type == 'written'} );
          var req = reqs[0];

          if(this.props.filter == 'renewal') {
            if(req.completed && !req.purchased) {
              lastWrittenCompleted++;
            }
          }
          else if(req.completed) {
            lastWrittenCompleted++;
          }

        }
      });
      return index > lastWrittenCompleted;
    }
  }

  getRequirementList = (credential) => {
    var reqs = [];
    for(var i=0;i<credential.reqs.length;i++) {
      if(credential.reqs[i].type=='written') {
        if(this.props.filter && credential.level != "N/A") {
          if(this.props.filter == 'renewal') {
            if(credential.level <= this.props.course.max_level) {
              reqs.push(credential.reqs[i]);
            }
          }
          else if(this.props.filter == 'upgrade') {
            if(credential.level > this.props.course.max_level) {
              reqs.push(credential.reqs[i]);
            }
          }
          else {
            reqs.push(credential.reqs[i]);
          }
        }
        else {
          reqs.push(credential.reqs[i]);
        }
      }
    }
    return reqs;
  }

  getCourseShortName = (credential, req) => {
    if(credential.name == 'Cheer Building Level 7') {
      return req.name.substring(18, req.name.length);
    }

    return req.name.substring(8, req.name.length-10) + " Course";
  }

  render() {
    return (
      <div>
            {/* TABE HEADER */}

            { !this.props.hideHeader &&
            <Row className="table-header-line">
              <Column small={4} style={{paddingLeft:0}}>
                <div className="inProgress-table-header">Item</div>
              </Column>
              {/*
              <Column small={2}>
                <div className="inProgress-table-header">Time to Complete</div>
              </Column>	
              */}
              <Column small={4} medium={2}>
                <div className="inProgress-table-header">Status</div>
              </Column>	
              <Column small={2} className="hide-for-small-only">
                <div className="inProgress-table-header">Expires</div>
              </Column>	
              <Column small={2} className="text-center hide-for-small-only">
                <div className="inProgress-table-header">Cost</div>
              </Column>	
              <Column small={4} medium={2} className="text-center">
                <div className="inProgress-table-header">Action</div>
              </Column>	
            </Row>
            }
            {/* TABLE BODY */}
            { this.getCredentials().map( (credential, index) =>
              <div key={index}>
                { this.getRequirementList(credential).map( (req, subIndex) => 
                  <div className={this.isReqLocked(index, subIndex) ? 'locked progress-row' : 'progress-row'} key={req.id}>
                    <Row style={{paddingTop:'2em'}}>
                      <Column small={4}  style={{paddingLeft:0}}>
                        <div className="">
                          { req.completed && !req.is_renewing &&
                            <img src={tick} className="tick" alt="tick"/>
                          }

                          { this.getCourseShortName(credential, req) }
                          {"  "}
                          { this.isReqLocked(index, subIndex) &&
                            <span className="req-lock">
                              <FaLock/>
                            </span>
                          }
                        </div>
                      </Column>
                      {/*
                      <Column small={2}>
                        <div className="inProgress-table-header">Time to Complete</div>
                      </Column>	
                      */}
                      <Column small={4} medium={2}>
                        {req.completed &&
                          <div className="">Completed {formatTimestamp(req.completed_date)}</div>
                        }
                        {!req.completed &&
                          <div className="">Incomplete</div>
                        }
                      </Column>
                      <Column small={2} className="hide-for-small-only">
                        <div className="">{ credential.expiration == null ? '-' : credential.expiration}</div>
                      </Column>
                      {subIndex <= 0 &&
                      <Column small={2} className="text-center hide-for-small-only">
                        <div className="">{ (req.purchased || req.completed_date>credential.last_event || req.price == 0) ? 'PAID' : formatMoney(credential.credential_price) }</div>
                      </Column>
                      }
                      {subIndex > 0 &&
                      <Column small={2} className="text-center hide-for-small-only">
                        <div className=""></div>
                      </Column>
                      }
                      <Column small={4} medium={2} className="text-center">
                        <div className="">
                          <NextStepButton
                            isLocked={this.isReqLocked(index, subIndex)}
                            req={req}
                            shortText={true}
                            allowAddToCart={req.completed_date<=credential.last_event && subIndex == 0}
                            course={this.props.course}
                            price={credential.credential_price}
                            naText={subIndex > 0 ? " " : false}
                          />
                        </div>
                      </Column>	
                    </Row>
                  </div>
                )}
              </div>
            )}
      </div>
    )
  }


}

function mapStateToProps(state) {
  const {account, commerce} = state;
  return {
    cart: commerce.cart,
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

const mapDispatchToProps = {
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequirementTable));
