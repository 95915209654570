import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionContent, AccordionTitle, Row, Column, Button } from 'react-foundation';
import icon_chevron from "../../../images/icons/Chevron.svg";
import InProgressTable from './inProgressTable';
import Practicals from './practicals';

export default class CollapseItem extends Component {
	constructor() {
		super();
		this.state = {
			activeIndex1: false,
			activeIndex2: false,
			activeIndex3: false,
			activeIndex4: false,
			activeIndex5: false,
			activeIndex6: false,
		}
	}

	render() {		
		return (
			<Row>
				<Column small={12} className="tab-collapse">		
					<Accordion>
						<AccordionItem isActive={this.state.activeIndex2}>
							<AccordionTitle>
								<Row>
									<Column small={12} className="text-left inprogress-item">
										<div className="text-left">
											<div className="inprogress-accordion-title">Cheer Building</div>
											<div className="complete-date">
											{this.props.tab1?
											<div className="question-mark">?</div>: 
											"In-Progress"
											}
											</div>	
										</div>					
										<div className="text-right">
											<Button className="chevron-button-inprogress" onClick={(e) => { this.setState({ activeIndex2: !this.state.activeIndex2 }) }}>
												<img src={icon_chevron} className={this.state.activeIndex2 ? "chevron-reverse":null} alt="Chevron" />
											</Button>
										</div>	
									</Column>
								</Row>
							</AccordionTitle>
							<AccordionContent isActive={this.state.activeIndex2} onClick={(e) => { this.setState({ activeIndex2: !this.state.activeIndex2 }) }}>
								<InProgressTable />
							</AccordionContent>
						</AccordionItem>

						<AccordionItem isActive={this.state.activeIndex5}>
							<AccordionTitle>
								<Row>
									<Column small={12} className="text-left inprogress-item">
										<div className="text-left">
											<div className="inprogress-accordion-title">Practicals Available for Testing</div>
											<div className="complete-date">
												{/* In-Progress */}
												<div className="question-mark">?</div>
											</div>
										</div>					
										<div className="text-right">
											<Button className="chevron-button-inprogress" onClick={(e) => { this.setState({ activeIndex5: !this.state.activeIndex5 }) }}>
												<img src={icon_chevron} className={this.state.activeIndex5 ? "chevron-reverse":null} alt="Chevron" />
											</Button>
										</div>	
									</Column>
								</Row>
							</AccordionTitle>
							<AccordionContent isActive={this.state.activeIndex5} onClick={(e) => { this.setState({ activeIndex5: !this.state.activeIndex5 }) }}>
								<Practicals />
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				</Column>	
			</Row>
		)
	}
}
