import React, { Component } from 'react';
import {Row, Column, Button} from 'react-foundation';
import CollapsibleGroup from './CollapsibleGroup';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';

import { FaSpinner, FaCheck} from 'react-icons/fa';
import { GoCalendar } from 'react-icons/go';
import {fetchPracticalTestSessionAvailability, scheduleLevel7Practical} from '../actions/practicalTest';

import Loading from './Loading';
import {startCourse, formatMoney} from '../util/credentials';
import {addItemToCart} from '../actions/commerce';
import AddToCartModal from './modals/AddToCartModal';
import {getAvailablePracticals} from '../util/credentials';

class SchedulePractical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      selectedPracticals: [],
      selectedPracticalDate: null,
      selectedPracticalTime: null,
      selectedPracticalType: null,
      selectedPracticalLocation: null,
      isLvl7Selected: false,
    }
    this.props.fetchPracticalTestSessionAvailability();
  }

  isPracticalSelected = (id) => {
    if(this.state.selectedPracticals.indexOf(id) !== -1)
      return true;
    else
      return false;
  }

  togglePracticalSelection = (id) => {
    if(!this.isPracticalSelectable(id)) return false;

    var newSelectionList = [];
    var isLvl7Selected = false;
    if(this.isPracticalSelected(id)) {
      for(var i=0;i<this.state.selectedPracticals.length;i++) {
        if(this.state.selectedPracticals[i] != id)
          newSelectionList.push(this.state.selectedPracticals[i]);
      }
    }
    else if(this.practicalIdToName(id) == "Cheer Building Level 7 Practical") {
      newSelectionList = [id];
      isLvl7Selected = true;
    }
    else {
      newSelectionList = [];
      for(var i=0;i<this.state.selectedPracticals.length;i++){
        if(this.practicalIdToName(this.state.selectedPracticals[i]) != "Cheer Building Level 7 Practical") {
          newSelectionList.push(this.state.selectedPracticals[i]);
        }
      }
      newSelectionList.push(id);
    }
    this.setState({
      selectedPracticals: newSelectionList,
      isLvl7Selected: isLvl7Selected,
      selectedPracticalDate: null,
      selectedPracticalTime: null,
    });
  }

  setPracticalDate = (date, time = null, type = 'virtual', location = null) => {
    console.log(date,time,type,location);
    this.setState({
      selectedPracticalDate: date,
      selectedPracticalTime: time,
      selectedPracticalType: type,
      selectedPracticalLocation: location
    });
  }

  isPracticalVirtualDateSelected = (date) => {
    return (this.state.selectedPracticalType == 'virtual' && this.state.selectedPracticalDate == date);
  }

  isPracticalEventDateSelected = (date) => {
    return (this.state.selectedPracticalType == 'event' && this.state.selectedPracticalDate == date);
  }

  handleChangeSelectionTime = (time) => {
    this.setState({
      selectedPracticalTime: time,
    });
  }

  hourToTime = (hour) => {
    if(hour == 12)
      return hour + ":00pm EST";
    if(hour > 12)
      return hour-12 + ":00pm EST";
    else
      return hour + ":00am EST";
  }

  canProceedToConfirmation = () => {
    return this.state.selectedPracticalDate != null && this.state.selectedPracticalTime != null;
  }

  practicalIdToName = (id) => {
    let ps = getAvailablePracticals(this.props.credentials);
    for(var i=0;i<ps.length;i++){
      if(ps[i].id == id) return ps[i].name;
    }
    return null;
  }

  addPracticalsToCart = () => {
    var practicalNames = [];

    getAvailablePracticals(this.props.credentials).filter((p)=>{return this.isPracticalSelected(p.id)}).map((practical)=>{
      practicalNames.push(practical.name);
    });

    this.props.addItemToCart(
      this.props.cart,
      {
        "id":    'practical-session',
        "name" : this.state.selectedPracticalType=='event' ? 'In-Person Practical Session' : 'Virtual Practical Session',
        "price": this.getSessionFee(),
        "type": "practical-"+this.state.selectedPracticalType,
        "date": this.state.selectedPracticalDate,
        "time": this.state.selectedPracticalTime,
        "location": this.state.selectedPracticalLocation,
        "time_pretty": this.hourToTime(this.state.selectedPracticalTime),
        "practicals": this.state.selectedPracticals,
        "practical_names": practicalNames
      }
    );
  }

  getAvailableSessions = (type) => {
    if(type == 'virtual') {
      if(this.state.isLvl7Selected) {
        return this.props.schedules.virtual_lvl7.filter((schedule)=>{return schedule.time_slots.length > 0});
      }
      return this.props.schedules.virtual.filter((schedule)=>{return schedule.time_slots.length > 0});
    }
    // @ToDo add level 7 exception for in-person practicals
  }

  getSessionFee = () => {
    return this.state.selectedPracticals.length <= 6 ? 25 : 50;
  }

  isPracticalSelectable= (practicalId) => {
    var isPracticalSelectable = false;

    var availablePracticals = [];
    var currentPractical = null;
    getAvailablePracticals(this.props.credentials).forEach(practical=>{
        if(practical.id == practicalId) {
            currentPractical = {
                id: practical.id,
                name: practical.name,
                group: practical.name.substring(6, 14),
                level: practical.name[21],
                selected: this.state.selectedPracticals.indexOf(practical.id)>=0
            }
        }
        availablePracticals.push({
            id: practical.id,
            name: practical.name,
            group: practical.name.substring(6, 14),
            level: practical.name[21],
            selected: this.state.selectedPracticals.indexOf(practical.id)>=0
        });
    });

    // Filter Practicals by group
    availablePracticals = availablePracticals.filter(p=>p.group==currentPractical.group);

    // Sort Available Practicals by level
    availablePracticals.sort(function(a,b) {
        return a.level - b.level;
    });

    if(currentPractical.selected) {
        var selectedPracticals = availablePracticals.filter(p=>p.selected===true);
        return selectedPracticals[selectedPracticals.length-1].id===currentPractical.id;
    }
    // Is lowest level in group available
    else if(availablePracticals[0].id == practicalId || currentPractical.name === "Cheer Building Level 7 Practical") {
        return true;
    }
    else {
        var previousPractical = availablePracticals.filter(p=>p.level==(currentPractical.level-1))[0];
        if(typeof previousPractical !== "undefined")
            return previousPractical.selected;
    }
    return false;
  }

  render() {
    return (
    <>
      <Row>
        <Column small={12}>
          <div className="breadcrumb">

            { this.state.step == 1 &&
            <Link to="/">{"< "}&nbsp; Back to Dashboard</Link>
            }
            { this.state.step == 2 &&
              <a href="#back" onClick={(e)=>{this.setState({step:1})}}>
                {"< "}&nbsp; Back to Select Tests
              </a>
            }
            { this.state.step == 3 &&
              <a href="#back" onClick={(e)=>{this.setState({step:2})}}>
                {"< "}&nbsp; Back to Select Time
              </a>
            }
          </div>

          { this.state.step == 1 &&
          <h1>Schedule a Practical Session</h1>
          }
          { this.state.step == 2 &&
          <h1>Available Time Slots</h1>
          }
          { this.state.step == 3 &&
          <h1>Confirm</h1>
          }
          <div className="card schedule-practical" ref={this.containerRef}>
             <Row className="card-row">

                { this.props.isFetching   &&
                  <Loading/>
                }
                { !this.props.isFetching && this.state.step == 1 &&
                <>
                  <Column small={12} medium={6}>
                    <h3>Instructions:</h3>
                    <p>
                        You must select a practical for EACH level you completed a written test in.  Select Level 1 first and work your way up to the highest level needing completed.  You can test up to 6 levels at on practical session for $25 (i.e. Levels 1-6 Building OR Levels 1-6 Tumbling OR levels 1-3 Building / Levels 1-3 Tumbling OR Levels 1-4 Building / Levels 1-2 Tumbling, etc). 
                    </p>
                    <p>
                        If you need to to take more than 6 level tests the system will automatically add an additional $25 fee into your cart when you select your 7th test.
                    </p>
                  </Column>
                  <Column small={12} medium={6} className="practical-list">
                    <h3>Select Practicals:</h3>
                    { getAvailablePracticals(this.props.credentials).filter((practical)=>{return practical.name !== "Cheer Building Level 7 Practical"}).length <= 0 &&
                      <div>
                        No practicals available.
                      </div>
                    }
                    { getAvailablePracticals(this.props.credentials).filter((practical)=>{return practical.name !== "Cheer Building Level 7 Practical"}).map((practical)=>
                      <div className="practical-list-item" key={practical.id}>
                        <div className={"clickable-wrap " + (!this.isPracticalSelectable(practical.id) ? "not-selectable" : "")} onClick={(e)=>{this.togglePracticalSelection(practical.id)}}>
                          <div className={this.isPracticalSelected(practical.id) ? 'selection-state-icon selected' : 'selection-state-icon'} >
                          { this.isPracticalSelected(practical.id) &&
                            <FaCheck className="icon"/>
                          }
                          </div>
                          <span className="name">
                              {practical.name}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="text-right">
                      { this.state.selectedPracticals.length == 0 &&
                        <Button className="card-button" disabled>Next: Select a Time</Button>
                      }
                      { this.state.selectedPracticals.length > 0 &&
                        <Button className="card-button" onClick={(e)=>{this.setState({step:2})}} >Next: Select a Time</Button>
                      }
                    </div>
                  </Column>
                </>
                }
                { !this.props.isFetching && this.state.step == 2 &&
                  <>
                    <Column small={12} large={5} medium={6} className="practical-list">
                      <h3>Virtual Practical Sessions:</h3>
                      { this.getAvailableSessions('virtual').length == 0 &&
                        <p><i style={{color:'#979696',fontSize:'.75em'}}>No virtual sessions are available at this time.</i></p>
                      }
                      { this.getAvailableSessions('virtual').map ((day, index)=> 
                        <div className="practical-list-item" key={index}>
                          <div className="clickable-wrap" onClick={(e)=>{
                            var currentTime = this.state.selectedPracticalTime;
                            // if(currentTime == null) currentTime = day.time_slots[0].key;
                            this.setPracticalDate(day.date, day.time_slots[0].key)
                          }}>

                            { this.isPracticalVirtualDateSelected(day.date) &&
                              <div className='selection-state-icon selected'>
                                <FaCheck className="icon"/>
                              </div>
                            }

                            <span className={this.isPracticalVirtualDateSelected(day.date) ? 'selected pc-item-title' : 'pc-item-title'} >
                              {day.date}
                              { this.isPracticalVirtualDateSelected(day.date) &&
                                <>
                                  {" at "}
                                  <select className="time-picker" onClick={(e)=>{e.stopPropagation();}} onChange={(e)=>{
                                    var value = e.target.value;
                                    this.setPracticalDate(day.date, value);
                                  }}>
                                      {day.time_slots.map( (time) =>
                                        <option value={time.key} key={time.key}>{time.value}</option>
                                      )}
                                  </select>
                                </>
                              }
                            </span>
                          </div>
                        </div>
                      )}
                    </Column>
                     <Column small={12} large={7} medium={6} className="practical-list">
                      <h3>In-Person Practical Session:</h3>
                      {  this.props.schedules.events.length == 0 &&
                        <p><i style={{color:'#979696',fontSize:'.75em'}}>No in-person sessions are available at this time.</i></p>
                      }
                      { this.props.schedules.events.map ((_event, index)=> 
                        <div className="event-schedule practical-list-item" key={index}>
                            <h4><GoCalendar/>{" "}{_event.name}{" in "}{_event.location}</h4>

                            { _event.time_slots.map( (day, index) =>
                              <div className="clickable-wrap" onClick={(e)=>{
                                this.setPracticalDate(day.value, day.hours[0].key, 'event', (_event.name+" in "+_event.location));
                              }} key={index}>

                                { this.isPracticalEventDateSelected(day.value) &&
                                  <div className='selection-state-icon selected'>
                                    <FaCheck className="icon"/>
                                  </div>
                                }

                                <span className={this.isPracticalEventDateSelected(day.value) ? 'selected pc-item-title' : 'pc-item-title'} >
                                  {day.value}
                                  { this.isPracticalEventDateSelected(day.value) &&
                                  <>
                                    {" at "}
                                    <select className="time-picker" onClick={(e)=>{e.stopPropagation();}} onChange={(e)=>{
                                      var value = e.target.value;
                                      this.setPracticalDate(day.value, value, 'event', (_event.name+" in "+_event.location));
                                    }}>
                                        {day.hours.map( (time) =>
                                          <option value={time.key} key={time.key}>{time.value}</option>
                                        )}
                                    </select>
                                  </>
                                  }
                                </span>
                              </div>
                            )}
                        </div>
                      )}
                    </Column>
                    <Column small={12} className="text-right">
                      { this.canProceedToConfirmation() &&
                      <>
                        <span style={{paddingRight:'3em', fontWeight:'bold', fontSize:'1.25rem'}}>
                        Selected time: 
                        {" "}
                        { this.state.selectedPracticalDate}
                        {" - "}
                        { this.hourToTime(this.state.selectedPracticalTime) }
                        {" "}
                        </span>
                        <Button className="card-button" onClick={(e)=>{this.setState({step:3})}}>Next: Confirm</Button>
                      </>
                      }
                      
                    </Column>
                  </>
                }
                { !this.props.isFetching && this.state.step == 3 &&
                  <Column small={12} className="schedule-practical-confirmation">
                    <Row>
                      <Column small={12} medium={9}>
                        { this.state.selectedPracticalType == 'event' &&
                          <h3>In-Person Practical Session:</h3>
                        }
                        { this.state.selectedPracticalType == 'virtual' &&
                          <h3>Virtual Practical Session:</h3>
                        }
                        <span>
                          Selected time: 
                          {" "}
                          { this.state.selectedPracticalDate}
                          {" - "}
                          { this.hourToTime(this.state.selectedPracticalTime) }
                          {" "}
                        </span>
                        <br/>
                        <br/>
                        <br/>
                        <h3>Practicals You'll Be Testing For:</h3>
                        { getAvailablePracticals(this.props.credentials).filter((p)=>{return this.isPracticalSelected(p.id)}).map((practical)=>
                          <div key={practical.id}>{practical.name}</div>
                        )}
                      </Column>
                      <Column small={12} medium={3}>
                        <div className="price-wrap">
                          {!this.state.isLvl7Selected &&
                            <div>
                              <h3 style={{width:'100%', textAlign:'left'}}>
                                Session Fee:
                              </h3>
                              <div className="session-price">
                                { "$" + this.getSessionFee() + ".00" }
                              </div>

                              <Button className="card-button"
                               onClick={(e)=>{
                                 this.addPracticalsToCart();
                              }}>Add to Cart</Button>
                            </div>
                          }
                          {this.state.isLvl7Selected &&
                            <div>
                              <h3 style={{width:'100%', textAlign:'left'}}>
                                Session Fee:
                              </h3>
                              <div className="session-price">
                                PAID
                              </div>

                              <Button className="card-button"
                              disabled={this.props.isScheduling}
                               onClick={(e)=>{
                                this.setState({step:4});
                                 this.props.scheduleLevel7Practical(this.state.selectedPracticalDate+" "+this.hourToTime(this.state.selectedPracticalTime));
                              }}>Schedule Practical</Button>
                            </div>
                          }
                        </div>
                      </Column>
                    </Row>
                  </Column>
                }
                { this.state.step == 4 &&
                  <Column small={12} className="schedule-practical-confirmation text-center">
                    {this.props.isScheduling &&
                      <div>
                        <h1>Scheduling Practical</h1>
                        <p>Please wait</p>
                        <Loading/>
                      </div>
                    }
                    {!this.props.isScheduling && this.hasSchedulingError &&
                      <div>
                        <h1>Scheduling Practical</h1>
                        <p>Sorry, there was an error.</p>
                        <Button className="card-button"
                        disabled={this.props.isScheduling}
                         onClick={(e)=>{
                           this.props.scheduleLevel7Practical(this.state.selectedPracticalDate+" "+this.hourToTime(this.state.selectedPracticalTime));
                        }}>Try Again</Button>
                      </div>
                    }
                    {!this.props.isScheduling && !this.hasSchedulingError &&
                      <div>
                        <h1>Practical Scheduled</h1>
                        <p>Your practical has been scheduled!</p>
                        <p><strong>{this.state.selectedPracticalDate+" "+this.hourToTime(this.state.selectedPracticalTime)}</strong></p>
                        <Link to="/">
                          <Button className="card-button">
                            Back to Dashboard
                          </Button>
                        </Link>
                      </div>
                    }
                  </Column>
                }


              </Row>
          </div>
        </Column>
      </Row>
      <AddToCartModal {...this.props}/>
      </>
    )
  }
}


function mapStateToProps(state) {
  const {account, practicalTest, commerce} = state;
  return {
    cart: commerce.cart,
    isFetching: practicalTest.isFetching || account.isFetching,
    isScheduling: practicalTest.isScheduling,
    hasSchedulingError: practicalTest.hasSchedulingError,
    schedules: practicalTest.schedules,
    credentials: account.credentials,
  };
}
const mapDispatchToProps = {
  fetchPracticalTestSessionAvailability: fetchPracticalTestSessionAvailability,
  addItemToCart: addItemToCart,
  scheduleLevel7Practical: scheduleLevel7Practical
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePractical);
