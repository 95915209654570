import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import {connect} from 'react-redux';
import icon_chevron from "../images/icons/Chevron.svg";

class CollapsibleGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: this.props.isCollapsed,
    }
  }

  render() {
    return (
      <div className="collapsible-group">
        <Column small={12} className="text-left inprogress-item">
          <div className="text-left">
            <div className="inprogress-accordion-title">
              {this.props.header}
              { typeof this.props.tag !== 'undefined' && this.props.tag !== '' &&
                <span className="title-tag">{this.props.tag}</span>
              }
            </div>
          </div>
          <div className="text-right">
            <Button className="chevron-button-inprogress" onClick={(e) => { this.setState({ isCollapsed: !this.state.isCollapsed }) }}>
              <img src={icon_chevron} className={!this.state.isCollapsed ? "chevron-reverse":null} alt="Chevron" />
            </Button>
          </div>
        </Column>

        <Column className="collapsible-group-inner-wrap" small={12} style={{maxHeight: (this.state.isCollapsed) ? 0 : 1000, overflow:'hidden'}}>
          {this.props.children}
        </Column>
      </div>

    );
  }

}
export default CollapsibleGroup;
