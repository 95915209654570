import React, { Component } from 'react';
import {Row, Column} from 'react-foundation';
import CollapseItem from './collapseItem';
import tick from "../../../images/tick.png";

export default class InProgressItem extends Component {
	constructor() {
		super();
		this.state = {			
		}
	}

	render() {
		return (
			<div>					
				<Row>
					<Column small={12} className="text-left inprogress-item">
						<img src={tick} className="tick" alt="tick"/>
						<div className="inprogress-item-title">Darkness to Light</div>
						<div className="complete-date">Completed 5/8/19</div>
					</Column>
					<Column small={12} className="text-left inprogress-item">
						<img src={tick} className="tick" alt="tick"/>
						<div className="inprogress-item-title">Baseline Coach Course</div>
						<div className="complete-date">Completed 5/8/19</div>
					</Column>
					<Column small={12} className="text-left inprogress-item">
						<img src={tick} className="tick" alt="tick"/>
						<div className="inprogress-item-title">Positive Coach Alliance</div>
						<div className="complete-date">Completed 5/8/19</div>
					</Column>
				</Row>			
				<CollapseItem tab1={false}/>			
			</div>
		)
	}
}

