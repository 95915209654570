import React from 'react';
import {Button, Progress} from 'react-foundation';
import {Row, Column} from 'react-foundation';
import {withRouter} from 'react-router-dom';
import { FiAlertCircle } from "react-icons/fi";
import icon_chevron from "../../images/icons/Chevron.svg";
import TabView from '../tabView';
import InProgressTable from '../InProgressTable';
import PreReqList from '../PreReqList';
import AvailablePracticals from '../AvailablePracticals';
import RequirementTable from '../RequirementTable';
import CredentialHistory from '../CredentialHistory';
import {
  getNextStep, 
  getHighestLevelCompleted,
  getCoursePercentageCompletion,
  startNextReq,
  getNextReq
} from "../../util/credentials";
import NextStepButton from './NextStepButton';
import NextStepText from './NextStepText';

class ProgressCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTabs: this.props.expand,
    }
  }

  calculatePercentageComplete = () => {
    if(typeof this.props.course.max_level === 'undefined') {
      return 0;
    }
    else {
      var maxLevel = this.props.course.max_level;
      if(typeof this.props.course.credentials[maxLevel] !== 'undefined') {
        var totalReqs = 0;
        var completedReqs = 0;
        totalReqs += this.props.course.credentials[maxLevel].pre_reqs.length;
        totalReqs += this.props.course.credentials[maxLevel].reqs.length;
        for(var i=0;i<this.props.course.credentials[maxLevel].pre_reqs.length;i++) {
          if(this.props.course.credentials[maxLevel].pre_reqs[i].completed) completedReqs++;
        }
        for(var i=0;i<this.props.course.credentials[maxLevel].reqs.length;i++) {
          if(this.props.course.credentials[maxLevel].reqs[i].completed) completedReqs++;
        }
        return Math.round(completedReqs/totalReqs*100);
      }
      if(maxLevel > 0) return 100;
      else return 0;
    }
  }

    render () {
        return (
          <Column className="credentialing-card" large={this.state.showTabs? 12 : 4} medium={this.state.showTabs? 12: 6} small={12}>
            <div className="card">
              { !this.state.showTabs &&
             <Row className="card-row">
                <Column small={10}>
                  <Row>
                    <Column small={12}>
                      <div className="card-title">{this.props.course.name}</div>
                    </Column>
                  </Row>
                </Column>
              </Row>
              }
              { this.state.showTabs &&
               <Row className="card-row">
                  <Column small={12}>
                    <Row>
                      <Column medium={9} small={12}>
                        <div className="card-title">{this.props.course.name}</div>
                      </Column>
                      <Column small={12} medium={3} className="hide-for-small-only">
                        <Row>
                          <Column small={12} className="text-center">
                            <div className="card-title percentage" >
                              <span>
                         
                              </span>
                            </div>
                          </Column>
                        </Row>
                      </Column>
                    </Row>
                  </Column>
                </Row>
              }


                <Row className="card-row">
                  <Column small={12}>
                    <div className="card-sub-title">
                      <NextStepText course={this.props.course} allowAddToCart={true} req={getNextReq(this.props.course, true)}/>
                    </div>
                  </Column>
                  <Column medium={10} small={12}>
                    <NextStepButton course={this.props.course} allowAddToCart={true} req={getNextReq(this.props.course, true)}/>
                  </Column>
                  <Column medium={2} small={12}  className="text-right">
                    <Button className="chevron-button mobile-center" 
                      onClick={(e) => { this.setState({ showTabs: !this.state.showTabs }) }}
                    >
                      <img src={icon_chevron} className={this.state.showTabs ? "chevron-reverse":null} alt="Chevron" />
                    </Button>
                  </Column>
                </Row>

                {this.state.showTabs?
                  (
                    <div>
                      <div className="horizontal-line"></div>
                      <TabView>
                        <div title="Next Steps">
                          <RequirementTable 
                            course={this.props.course}
                            allowRenewal={false}
                          />
                          <br/>
                          <br/>
                          <AvailablePracticals
                            course={this.props.course}
                          />
                        </div>
                        <div title="In Progress">
                          {this.props.course.pre_reqs &&
                              <PreReqList
                                course={this.props.course}
                              />
                          }
                          <RequirementTable 
                            course={this.props.course}
                            allowRenewal={false}
                          />
                          <br/>
                          <br/>
                          <AvailablePracticals
                            course={this.props.course}
                          />
                        </div>
                        <div title={this.props.course.name + " History"}>
                          <CredentialHistory
                            course={this.props.course}
                          />
                        </div>
                      </TabView>
                    </div>
                  ):
                    null
                 }
            </div>
          </Column>
        )
    }
}

export default withRouter(ProgressCard);
