import React from 'react';
import { FaSpinner} from 'react-icons/fa';

const Loading = props => (
  <div style={{fontSize:'3em', width:'100%', textAlign:'center'}}>
    <FaSpinner className='fa-spin'/>
  </div>
)
export default Loading;

