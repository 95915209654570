import $ from "jquery";
import {logout} from "./authentication";

export const ACCOUNT_FETCH_START = 'ACCOUNT_FETCH_START';
export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_FAILURE = 'ACCOUNT_FETCH_FAILURE';

export const CREDENTIALS_FETCH_START   = 'CREDENTIALS_FETCH_START';
export const CREDENTIALS_FETCH_SUCCESS = 'CREDENTIALS_FETCH_SUCCESS';
export const CREDENTIALS_FETCH_FAILURE = 'CREDENTIALS_FETCH_FAILURE';



export const  fetchAccount = () => {
  return dispatch => {
      dispatch({
        type:  ACCOUNT_FETCH_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/profile'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {

      dispatch({
        type:  ACCOUNT_FETCH_SUCCESS,
        data: response.data
      });
      dispatch(fetchCredentials());

    } ).fail(()=> {
      logout();
      // dispatch({
      //   type:  AUTH_FAILURE
      // });
    });
  }
}

export const fetchCredentialHistory = () => {
  return dispatch => {
     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/credential-history'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {

      dispatch({
        type:  ACCOUNT_FETCH_SUCCESS,
        data: {history: response.data}
      });

    } ).fail(()=> {
      logout();
      // dispatch({
      //   type:  AUTH_FAILURE
      // });
    });
  }
}

export const fetchCredentials = () => {
  return dispatch => {
     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/credential'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {
      dispatch({
        type:  CREDENTIALS_FETCH_SUCCESS,
        data: response.data
      });
      dispatch(fetchCredentialHistory());
    } ).fail(()=> {
      logout();
      // dispatch({
      //   type:  AUTH_FAILURE
      // });
    });
  }
}
