import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

class WelcomeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };
  }

  shouldShowModal = () =>{
    if(this.props.account.isFetching)
      return false;

    if(this.state.isOpen == false) return false;

    var arePreReqsComplete = true;
    for(var i=0;i<this.props.account.prereqs.length;i++) {
      if(this.props.account.prereqs[i].custom_id == "pca" || this.props.account.prereqs[i].custom_id == "d2l") {
        if(!this.props.account.prereqs[i].is_complete) arePreReqsComplete = false;
      }
    }

    if(arePreReqsComplete) return false;
    if(document.cookie.match(/^(.*;)?\s*welcome_modal\s*=\s*[^;]+(.*)?$/)) return false
    return true;
  }

  closeModal = () => {
    this.setState({isOpen: false});
  }

  isPreReqPurchased = (cid) => {
    for(var i=0;i<this.props.account.prereqs.length;i++) {
      if(this.props.account.prereqs[i].custom_id == cid) {
        return this.props.account.prereqs[i].purchased;
      }
    }
  }

  isPreReqComplete = (name) => {
    for(var i=0;i<this.props.account.prereqs.length;i++) {
      if(this.props.account.prereqs[i].name == name) {
        return this.props.account.prereqs[i].is_complete;
      }
    }
  }

  delayReminder = () => {
    var exdays = 7;
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = "welcome_modal=set;" + expires + ";path=/";
    this.setState({isOpen: false});
  }


  render() {
    if(this.shouldShowModal()) {
      return(
        <div>
          <div className="modal-overlay" onClick={this.closeModal}></div>
          <div className="modal-content" id="welcome-modal">
            <a href="#close" className="close-modal" onClick={this.closeModal}><FaTimes/></a>
            <div className="inner-content">
              <h1>Welcome to the new USASF Credentialing System!</h1>
              <p>
                We now require that everyone take the new <strong>Baseline Coach Course</strong> and the 
                <strong> Darkness to Light — Child Sexual Abuse Prevention Training</strong> as prerequisites 
                to all other courses. You will need to successfully complete these courses
                before moving on to any other courses. All of your credentialing and course
                history should already be uploaded into the new system. If you have any 
                questions or concerns, please contact us by emailing <strong>credentialing@usasf.net</strong>.
              </p>
              <div className="button-group">
              </div>
              <a href="#" onClick={this.delayReminder} className="reminder">Remind Me Later</a>
            </div>
          </div>
        </div>
      );
    }
    return(
      <div></div>
    );
  }
}
function mapStateToProps(state) {
  const { account } = state;
  return {
    account: account,
  };
}

export default connect(mapStateToProps)(WelcomeModal);
