import React, { Component } from 'react';
import {Row, Column, Button} from 'react-foundation';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import {formatTimestamp} from '../util/credentials';

class AllCredentialHistory extends Component {
  constructor(props) {
    super(props);
  }

  getCredentialHistories = (expired = false) => {
    var histories = [];
    for(var i=0;i<this.props.credentialHistory.length;i++) {
      if(expired && this.props.credentialHistory[i].expired) {
        histories.push(this.props.credentialHistory[i]);
      }
      else if(!expired && !this.props.credentialHistory[i].expired){
        histories.push(this.props.credentialHistory[i]);
      }
    }
    return histories;
  }

  getCredentialName = (name) => {
    return name.substring(14) + " Credential";
  }



  render() {
    if(!this.props.credentialHistory) {
      return (
        <Row>
          <Column small={12}>
            <div className="breadcrumb">
              <Link to="/">{"< "}&nbsp; Back to Dashboard</Link>
            </div>

            <h1>Your Profile</h1>

            <div className="card" ref={this.containerRef}>
               <Row className="card-row">
                <Loading/>
               </Row>
            </div>
          </Column>
        </Row>
      )
    }

    return (
    <>
      <Row>
        <Column small={12}>
          <div className="breadcrumb">
            <Link to="/">{"< "}&nbsp; Back to Dashboard</Link>
          </div>

          <h1>Your Profile</h1>

          <div className="card" ref={this.containerRef}>
             <Row className="card-row">

                { this.props.isFetching &&
                  <Loading/>
                }
                { !this.props.isFetching && (this.getCredentialHistories().length == 0 && this.getCredentialHistories(true).length == 0 ) &&
                  <>
                    <Column small={12} >
                      <h2>Your currently do not have any credentialing history.</h2>
                    </Column>
                  </>
                }
                { !this.props.isFetching && (this.getCredentialHistories().length > 0 || this.getCredentialHistories(true).length > 0) &&
                  <>
                    <Column small={12} className="all-credentialing-history">
                      <Row className="table-header-line">
                      <h2>Your Credentialing History</h2>
                      </Row>
                      {/* TABE HEADER */}
                      <Row className="table-header-line">
                        <Column small={6} style={{paddingLeft:0}}>
                          <div className="inProgress-table-header">Item</div>
                        </Column>
                        <Column small={3} className="hide-for-small-only">
                          <div className="inProgress-table-header">Date Completed</div>
                        </Column>	
                        <Column medium={3} small={6}>
                          <div className="inProgress-table-header">Expires</div>
                        </Column>
                      </Row>

                      { this.getCredentialHistories().length > 0 &&
                        <div>
                          {this.getCredentialHistories().map((history, index)=>
                            <div key={index}>
                            {history.reqs.length == 0 &&
                              <Row className="credential-row" key={index}>
                                <Column small={6} style={{paddingLeft:0}}>
                                  {history.name}
                                </Column>
                                <Column small={3} className="hide-for-small-only">
                                  { formatTimestamp(history.credential_date) == '-' &&
                                  <div className='date-unavailable'>
                                    (Unavailable)
                                  </div>
                                  }
                                  { formatTimestamp(history.credential_date) != '-' &&
                                  <div>
                                    {formatTimestamp(history.credential_date)}
                                  </div>
                                  }
                                </Column>	
                                <Column medium={3} small={6}>
                                  <div className="">{history.expires_on}</div>
                                </Column>
                              </Row>
                            }
                            {history.reqs.map((req, index)=>
                              <Row className="credential-row" key={index}>
                                <Column small={6} style={{paddingLeft:0}}>
                                  {req.name}
                                </Column>
                                <Column small={3} className="hide-for-small-only">
                                  <div className="">{formatTimestamp(req.completion_date)}</div>
                                </Column>	
                                <Column medium={3} small={6}>
                                  <div className="">{history.expires_on}</div>
                                </Column>
                              </Row>
                              )}
                            </div>
                          )}
                        </div>
                      }
                      {/* EXPIRED Credentials */}
                      { this.getCredentialHistories(true).length > 0 &&
                        <div>
                          <Row className="expired-line">
                            <Column className="small-centered"><span>Expired!</span></Column>
                          </Row>
                          {this.getCredentialHistories(true).map((history, index)=>
                            <div key={index}>
                            { history.reqs.length == 0 &&
                              <Row className="credential-row expired" key={index}>
                                <Column small={6} style={{paddingLeft:0}}>
                                  {history.name}
                                </Column>
                                <Column small={3}>
                                  <div className="">-</div>
                                </Column>	
                                <Column small={3}>
                                  <div className="">{history.expires_on}</div>
                                </Column>
                              </Row>
                            }
                            {history.reqs.map((req, index)=>
                              <Row className="credential-row expired" key={index}>
                                <Column small={6} style={{paddingLeft:0}}>
                                  {req.name}
                                </Column>
                                <Column small={3}>
                                  <div className="">{req.completion_date}</div>
                                </Column>	
                                <Column small={3}>
                                  <div className="">{history.expires_on}</div>
                                </Column>
                              </Row>
                              )}
                            </div>
                          )}
                        </div> 
                      }

                    </Column>
                  </>
                }
              </Row>
          </div>
        </Column>
      </Row>
    </>
    )
  }
}



function mapStateToProps(state) {
  const {account} = state;
  return {
    isFetching: account.isFetching,
    credentialHistory: account.history,
  };
}

export default connect(mapStateToProps)(AllCredentialHistory);
