import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Row, Column, Button} from 'react-foundation';
import {addItemToCart} from '../../actions/commerce';
import Loading from '../Loading';
import {startCourse, calculateLevel7Status} from "../../util/credentials";


class AvailableCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comingSoon: [
        // "Dance: Pom",
        // "Dance: Hip-Hop",
        // "Dance: Kick",
        // "Dance: Jazz",
        // "Exceptional Athletes: Dance",
        // "Dance Credentialing",
        "Exceptional Athletes: Cheer",
      ]
    }
  }

    getAvailableCourses = () => {
      var availableCourses = [];

      for (const property in this.props.credentials) {
        var credentialName = this.props.credentials[property].name;

        if(!this.props.credentials[property].in_progress && !this.props.credentials[property].is_credentialed) {
            var firstCredential = this.props.credentials[property].credentials[0].reqs[0];

            availableCourses.push({
              name: credentialName,
              price: firstCredential.price,
              id: firstCredential.id,
              cartName: firstCredential.name,
              isLocked: false,
              hasSingularReq: (this.props.credentials[property].credentials.length === 1
                && this.props.credentials[property].credentials[0].reqs.length === 1)
            });
        }
      }

      // if(typeof this.props.credentials.building !== 'undefined') {
      //   if(!this.props.credentials.building.in_progress && !this.props.credentials.building.is_credentialed) {
      //       var firstCredential = this.props.credentials.building.credentials[0].reqs[0];
      //       availableCourses.push({
      //         name: "Cheer Building",
      //         price: firstCredential.price,
      //         id: firstCredential.id,
      //         cartName: firstCredential.name,
      //         isLocked: false
      //       });
      //   }
      // }
      // if(typeof this.props.credentials.tumbling !== 'undefined') {
      //   if(!this.props.credentials.tumbling.in_progress && !this.props.credentials.tumbling.is_credentialed) {
      //       var firstCredential = this.props.credentials.tumbling.credentials[0].reqs[0];
      //       availableCourses.push({
      //         name: "Cheer Tumbling",
      //         price: firstCredential.price,
      //         id: firstCredential.id,
      //         cartName: firstCredential.name,
      //         isLocked: false
      //       });
      //   }
      // }

      // Level 7 logic
      try {
        let level7 = this.props.credentials.building.credentials[6];

        let lvl7Status = calculateLevel7Status(this.props.credentials);
        if(lvl7Status == 'not_started') {
          // Check if pre-reqs have been completed
          let isLocked = false;

          if(this.props.credentials.building.max_level < 6 || this.props.credentials.tumbling.max_level < 6) {
            isLocked = true;
          }
          else {
            for(let i=0;i<level7.pre_reqs.length;i++){
              if(!level7.pre_reqs[i].completed) {
                isLocked = true;
                break;
              }
            }
          }

          availableCourses.push({
            name: "Cheer Building Level 7",
            price: level7.reqs[0].price + level7.reqs[1].price,
            id: level7.reqs[0].id,
            cartName: "Cheer Building Level 7",
            isLocked: isLocked
          });
        }
      }
      catch(error){
        // Do nothing
      }

      return availableCourses;
    }

    render () {
        return (
          <div className="available-courses">
              <Row className="label-content-gap">
                <Column small={12} className="line-wrap">
                  <span className="horizontal-line-text">Available Courses</span>
                  <div className="horizontal-line-background"></div>
                </Column>
              </Row>
              { this.props.isFetching &&
                <Loading/>
              }
              { !this.props.isFetching &&
              <Row>
                {this.getAvailableCourses().map( (course, i) => 
                <Column large={4} small={12} key={i}>
                  <div className="card">
                     <Row className="card-row">
                        <Column small={10}>
                            <div className="card-title">{course.name}</div>
                        </Column>
                      </Row>
                      <Row className="card-row">
                        {course.name != "Cheer Building Level 7" && !course.hasSingularReq &&
                          <Column small={12}>
                             <div className="card-sub-title">Intro to Rules & Glossary</div>
                             <Button onClick={()=>{
                              startCourse({id:824}, this.props.history);
                            }} className="card-button">Start Course</Button>
                          </Column>
                        }
                        {(course.name == "Cheer Building Level 7" || course.hasSingularReq) && course.isLocked &&
                           <Column small={12}>
                             <Button disabled className="card-button">Not Eligible</Button>
                          </Column>
                        }
                        {course.hasSingularReq && !course.isLocked &&
                           <Column small={12}>
                             <Button onClick={()=>{
                              startCourse({id:course.id}, this.props.history);
                            }} className="card-button">Start Course</Button>
                          </Column>
                        }
                        {course.name == "Cheer Building Level 7" && !course.isLocked &&
                           <Column small={12}>
                             <Button onClick={()=>{
                              startCourse({id:course.id}, this.props.history);
                            }} className="card-button">Start Course</Button>
                          </Column>
                        }
                      </Row>
                  </div>
                </Column>
                )}
                {this.state.comingSoon.map( (course, i) => 
                 <Column large={4} small={12} key={i}>
                  <div className="card">
                     <Row className="card-row">
                        <Column small={10}>
                            <div className="card-title">{course}</div>
                        </Column>
                      </Row>
                      <Row className="card-row">
                        <Column small={12}>
                          <div className="card-sub-title"></div>
                          <Button className="card-button" disabled
                          >Coming Soon</Button>
                        </Column>
                      </Row>
                  </div>
                </Column>
                )}
              </Row>
              }
          </div>
        )
    }
}

function mapStateToProps(state) {
  const {account, commerce} = state;
  return {
    cart: commerce.cart,
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

const mapDispatchToProps = {
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableCourses));
