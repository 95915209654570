import React from 'react';
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga';

class AnalyticsProvider extends React.Component {
  constructor(props) {
    super(props);
    this.trackPageView(true);
  }

  trackPageView = (force = false) => {
    var currentPath = window.location.pathname;
    var lastPath = this.props.location.pathname;
    if(force || lastPath != currentPath) {
      if(process.env && process.env.NODE_ENV != 'development') {
        ReactGA.initialize('UA-143697235-1');
        ReactGA.pageview(currentPath + window.location.search);
      }
    }
  }

  componentWillUpdate() {
    this.trackPageView();
  }

  render() {
    return(this.props.children);
  }

}

export default withRouter(AnalyticsProvider);
