import React, { Component } from 'react';
import {Row, Column, Button} from 'react-foundation';
import tick from "../../../images/tick.png";

export default class InProgressTable extends Component {
	constructor() {
		super();
		this.state = {			
		}
	}

	render() {
		return (
			<div className="inProgress-table">
				<Row className="table-header-line">
					<Column small={2}>
						<div className="inProgress-table-header">Item</div>
					</Column>	
					<Column small={2}>
						<div className="inProgress-table-header">Time to Complete</div>
					</Column>	
					<Column small={2}>
						<div className="inProgress-table-header">Status</div>
					</Column>	
					<Column small={2}>
						<div className="inProgress-table-header">Expires</div>
					</Column>	
					<Column small={2}>
						<div className="inProgress-table-header">Cost</div>
					</Column>	
					<Column small={2}>
						<div className="inProgress-table-header">Action</div>
					</Column>	
				</Row>				
				<Row className="bottom-row" style={{paddingTop:15}}>
					<Column small={2} className="table-column">
						<Row>							
							<Column small={12}>
								<div className="inprogress-table-cell">Level 1 Course</div>
							</Column>
						</Row>
					</Column>
					<Column small={2} className="table-column">
						<Row>
							<Column small={12}>
								<div className="inprogress-table-cell">2 hours</div>
							</Column>
						</Row>
					</Column>
					<Column small={2} className="table-column">
						<Row>
							<Column small={12}>
								<div className="inprogress-table-cell">Incomplete</div>
							</Column>
						</Row>
					</Column>
					<Column small={2} className="table-column">
						<Row>
							<Column small={12}>
								<div className="inprogress-table-cell">-</div>
							</Column>
						</Row>
					</Column>
					<Column small={2} className="table-column">
						<Row>
							<Column small={12}>
								<div className="inprogress-table-cell">PAID</div>
							</Column>
						</Row>
					</Column>
					<Column small={2} className="table-column">
						<Row>
							<Column small={12}>
								<div className="inprogress-table-cell">
									<Button className="cell-button">Begin Course</Button>
								</div>
							</Column>
						</Row>
					</Column>
				</Row>

			</div>
		)
	}
}

