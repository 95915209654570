import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import {connect} from 'react-redux';

import CollapsibleGroup from './CollapsibleGroup';
import tick from "../images/tick.png";

import { FaLock } from 'react-icons/fa';

import NextStepButton from './progressCard/NextStepButton';
import AvailablePracticals from './AvailablePracticals';

import {startCourse, formatMoney, formatTimestamp} from '../util/credentials';
import {addItemToCart} from '../actions/commerce';
import {withRouter} from 'react-router-dom';

class PreReqList extends React.Component {
  constructor(props) {
    super(props);
  }

  isReqLocked = (index, renewing=false) => {
    var lastWrittenCompleted = 0;

    this.props.course.credentials.map((credential)=> {
      // console.log("credential", credential);
      if(typeof credential.reqs !== 'undefined') {
        credential.reqs.filter( (req)=>{ return req.type == 'written'} ).map( (req) => {
          if(req.completed) lastWrittenCompleted++;
          // else lastWrittenCompleted0; //break map
        })
      }
    });
    return index >= (lastWrittenCompleted+1);
  }

  // toggleIntroCourse = (course) => {
  //   var maxLevel = this.props.course.max_level;
  //   var cycle = this.props.course.cycle;
  //
  //   console.log(course);
  //   if(course.id == 824) {
  //     return false
  //   }
  //   else {
  //     return true;
  //   }
  // }

  render() {
    return (
        <Row>
          {this.props.course.credentials[0].pre_reqs.map( (req, index) => 
            <Column small={12} className="text-left inprogress-item" key={index}>
              { req.completed &&
                <div>
                  <div className="inprogress-item-title" style={{marginLeft:0, lineHeight:'2em'}}>
                    <img src={tick} className="tick" alt="tick" style={{float:'none', paddingRight:'.25em', width:'2em', height:'auto'}}/>
                    <span dangerouslySetInnerHTML={{__html:req.name}}/>
                  </div>
                  <div className="complete-date" style={{lineHeight:'4em', marginTop:0, marginBottom:0}}>Completed {formatTimestamp(req.completed_date)}</div>
                </div>
              }
              { !req.completed &&
                <div>
                  <div className="inprogress-item-title" style={{marginLeft:0}} dangerouslySetInnerHTML={{__html:req.name}}/>
                  <div style={{float:'right'}}>
                    { (req.price == 0 || req.purchased) &&
                      <Button onClick={(e)=>{startCourse(req, this.props.history);}}>Start Course</Button>
                    }
                    { req.price > 0 && !req.purchased &&

                       <Button className="card-button"
                        onClick={(e)=>{
                          this.props.addItemToCart(
                            this.props.cart,
                            {
                              "id":    req.id,
                              "name" : req.name,
                              "price": req.price,
                              "type": "course"
                            }
                          );
                        }}>Add to Cart</Button>
                    }
                  </div>
                </div>
              }
            </Column>
          )}
        </Row>
    )
  }


}

function mapStateToProps(state) {
  const {account, commerce} = state;
  return {
    cart: commerce.cart,
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

const mapDispatchToProps = {
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreReqList));
