
export const startCourse = (item, history) => {
  if(typeof item.custom_id !== 'undefined') {
    if(item.custom_id === 'd2l') {
      history.push('/darkness-to-light');
    }
    else if(item.custom_id === 'pca') {
      history.push('/pca');
    }
    else {
      history.push('/written/'+item.id);
    }
  }
  else {
    history.push('/written/'+item.id);
  }
}


export const getHighestLevelCompleted = (course) => {
  for(var i=0;i<course.credentials.length;i++) {
    if(course.credentials[i]['status'] !== 'COMPLETED') {
      return i+1;
    }
  }
  return course.credentials.length;
}

export const getNextReq = (course, ignorePurchaseRequirement = false) => {
  var availablePracticals = getAvailablePracticals({
    'course-group': course
  });

  for(var i=0;i<course.credentials.length;i++) {
    if(course.name !== "Cheer Building Level 7" && i >= 5) return;

    if(typeof course.credentials[i].pre_reqs !== "undefined") {
        for(var j=0;j<course.credentials[i].pre_reqs.length;j++) {
          if(course.credentials[i].pre_reqs[j]['completed'] != true && course.credentials[i].pre_reqs[j]['custom_id'] != 'pca') {
            return course.credentials[i].pre_reqs[j];
          }
        }
    }

    // Lvl 7 is currently missing reqs
    if(typeof course.credentials[i].reqs === 'object') {
      for(var j=0;j<course.credentials[i].reqs.length;j++) {
        if(course.credentials[i].reqs[j]['type'] == 'written' && ( (course.credentials[i].reqs[j]['completed'] != true && ignorePurchaseRequirement) || course.credentials[i].reqs[j]['purchased'])) {
          return course.credentials[i].reqs[j];
        }
        else {
          // Check if practical is available
          for(var ii=0;ii<availablePracticals.length;ii++) {
            if(course.credentials[i].reqs[j].id == availablePracticals[ii].id) {
              return course.credentials[i].reqs[j];
            }
          }
        }
      }
    }
  }
}

export const startNextReq = (course, history) => {
  for(var i=0;i<course.credentials.length;i++) {
    for(var j=0;j<course.credentials[i].reqs.length;j++) {
      if(course.credentials[i].reqs[j]['completed'] != true) {
        startCourse(
          course.credentials[i].reqs[j],
          history
        );
        return;
      }
    }
  }
}

export const getCompletedDate = (date) => {
}

export const formatMoney = (n, c, d, t) => {
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return s + '$' + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export const getAvailablePracticals = (credentials) => {
    var availablePracticals = [];
    for (var property in credentials) {
      if (credentials.hasOwnProperty(property)) {
        var courseGroup = credentials[property];

        for(var i=0;i<courseGroup.credentials.length;i++) {
          if(typeof courseGroup.credentials[i].reqs !== 'undefined') {
            var practicals = courseGroup.credentials[i].reqs.filter( (req)=>{ return req.type == 'practical'} );
            if(practicals.length > 0) {
              var writtenTests = courseGroup.credentials[i].reqs.filter( (req)=>{ return req.type == 'written'} );
              var completedWrittenTestCount = 0;
              writtenTests.map( written => {
                if(written.completed_date > courseGroup.credentials[i].last_event)  {
                  completedWrittenTestCount++;
                }
              });
              // Add practical to the list;
              if(completedWrittenTestCount == writtenTests.length) {
                for(var j=0;j<practicals.length;j++){
                  availablePracticals.push(practicals[j]);
                }
              }
            }
          }
        }
      }
    }

    return availablePracticals;
}

export const formatTimestamp = (timestamp) => {
  var date = new Date(timestamp * 1000);
  var month = date.getMonth() + 1;
  if(month < 10) month = "0"+month;

  var day = date.getDate();
  if(day < 10) day = "0"+day;

  var dateString = month +"/" + day + "/" + date.getFullYear();

  switch(dateString) {
    case "01/01/1970":
    case "12/31/2018":
    case "01/01/2019":
      return "-";
    case "12/31/1969":
    case "12/30/2018":
      return '';
    default:
      return dateString;
  }
}

export const formatTimestampWithTime = (timestamp) => {
  var date = new Date(timestamp * 1000);
  var month = date.getMonth() + 1;
  if(month < 10) month = "0"+month;

  var day = date.getDate();
  if(day < 10) day = "0"+day;

  var time = date.getUTCHours();
  if(time == 12) {
    time = "12:00pm EST";
  }
  else if(time > 12) {
    time = (time-12)+":00pm EST";
  }
  else {
    time = time+":00am EST";
  }

  if(date.getFullYear() <= 1970) return '';
  return month +"/" + day + "/" + date.getFullYear() + " " + time;
}

export const calculateLevel7Status = (credentials) => {
  let level7 = credentials.building.credentials[6];

  if(level7.last_event !== false)
      return 'credentialed';

  for(let i=0;i<level7.reqs.length;i++){
    if(level7.reqs[i].purchased || level7.reqs[i].completed) {
      return 'in_progress';
    }
  }

  return 'not_started';
}
