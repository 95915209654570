import React from 'react';
import getYouTubeID from 'get-youtube-id';

class VideoEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {src: this.parseSrc(this.props.src)};
  }
  parseSrc = (src) => {
    var ytid = getYouTubeID(src);
    if(ytid !== null)
      return "https://www.youtube.com/embed/" + ytid;
    else
      return src;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.src !== this.props.src) {
      this.setState({src: this.parseSrc(this.props.src)});
    }
  }

  render(){
    return (
      <div className="responsive-embed widescreen">
        <iframe src={this.state.src} style={{width:'100%'}} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>
      </div>
    );
  }
}

export default VideoEmbed;
