import React from 'react';
import {connect} from 'react-redux';
import {Row, Column} from 'react-foundation';
import tick from "../images/tick.png";
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {formatTimestamp} from '../util/credentials';
import Loading from './Loading';

class CredentialHistory extends React.Component {
  getCredentialHistories = (expired = false) => {
    var histories = [];
    for(var i=0;i<this.props.credentialHistory.length;i++) {
      if(
        this.props.credentialHistory[i].group == this.props.course.group 
        || this.props.credentialHistory[i].group == 'Other' && (
            this.props.credentialHistory[i].name == this.props.course.group 
        )
      )
      {
        if(expired && this.props.credentialHistory[i].expired) {
          histories.push(this.props.credentialHistory[i]);
        }
        else if(!expired && !this.props.credentialHistory[i].expired){
          histories.push(this.props.credentialHistory[i]);
        }
      }
    }
    return histories;
  }

  getCredentialName = (name, type) => {
    if(name.indexOf("Level") !== -1) {
        var appendedText = " Credential";
        if(type === 'renewed')
          appendedText = " Renewal";

        return name.substring(14) + appendedText;
    }
    else {
        var appendedText = "";
        if(type === 'renewed')
          appendedText = " Renewal";
        return name + appendedText;
    }
  }

  render() {
    if(!this.props.credentialHistory) {
      return(<Loading/>);

    }
    else if(this.getCredentialHistories().length == 0) {
      return(
        <div>
          <span className="tab-contents">History will appear when you successfully credential in {this.props.course.name}.</span>
        </div>
      );
    }
    else {
      return(
        <div className="credential-history">
          <Row className="table-header-line">
            <Column small={12} medium={6}>
              <div className="inprogress-accordion-title">
                {this.props.course.name} History
              </div>
            </Column>
            <Column small={12} medium={6} className="text-right hide-for-small-only">
              <Link to="/credential-history">View complete credentialing history</Link>
            </Column>
          </Row>
          {/* TABE HEADER */}
          <Row className="table-header-line">
            <Column small={6} style={{paddingLeft:0}}>
              <div className="inProgress-table-header">Item</div>
            </Column>
            <Column small={3} className="hide-for-small-only">
              <div className="inProgress-table-header">Date Completed</div>
            </Column>	
            <Column small={6} medium={3}>
              <div className="inProgress-table-header">Expires</div>
            </Column>	
          </Row>
          {this.getCredentialHistories().map((history, index)=>
            <Row className="credential-row" key={index}>
              <Column small={6} style={{paddingLeft:0}}>
                <div className="credential-name">
                  <img src={tick} className='tick'/>
                  {" "}&nbsp;
                  {this.getCredentialName(history.name, history.type)}
                </div>
                {history.reqs.map((req, index)=>
                  <div className="credential-req" key={index}>
                    <FaCheck/>
                    {" "}
                    {req.name}
                  </div>
                )}
              </Column>
              <Column small={3} className="hide-for-small-only">
                { formatTimestamp(history.credential_date) == '-' &&
                <div className='date-unavailable'>
                  (Unavailable)
                </div>
                }
                { formatTimestamp(history.credential_date) != '-' &&
                <div>
                  {formatTimestamp(history.credential_date)}
                </div>
                }
              </Column>	
              <Column small={6} medium={3}>
                <div className="">{history.expires_on}</div>
              </Column>
            </Row>
          )}
        </div>

      )
    }
  }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    credentialHistory: account.history,
  };
}

export default connect(mapStateToProps)(CredentialHistory);
