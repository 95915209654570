import $ from "jquery";

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const checkAuthentication = () => {
  return dispatch => {
     $.ajax( {
        url:  window.WP_API_Settings.endpoint + 'wp/v2/users/me?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {
      dispatch({
        type:  AUTH_SUCCESS
      });
    } ).fail(()=> {
      dispatch({
        type:  AUTH_FAILURE
      });
    });
  }
}

export const logout = () => {
  return dispatch => {
    // Immediatly log out user
    dispatch({
      type:  AUTH_FAILURE
    });

    // Delete cookie on server
     $.ajax({
        url:  window.WP_API_Settings.endpoint + 'usasf-api/v2/logout?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    });

  }
}
