import React, { Component } from 'react';
import CollapseItem from './common/collapseItem';

export default class NextSteps extends Component {
	constructor() {
		super();
		this.state = {
			activeIndex: 1
		}
	}

	render() {
		return (
			<div>				
				<CollapseItem tab1={true}/>	
			</div>
		)
	}
}

