import React from 'react';
import {Row, Column} from 'react-foundation';
import { connect } from 'react-redux'
import PrerequisiteItem from '../prerequisiteItem';
import Loading from '../Loading';


class PrerequisiteCourses extends React.Component {
    render () {
        return (
          <div className="prerequisite-courses">
             <Row className="label-content-gap">
                <Column small={12} className="line-wrap">
                  <span className="horizontal-line-text">Prerequisite Courses</span>
                  <div className="horizontal-line-background"></div>
                </Column>
              </Row>
              { this.props.isFetching &&
                <Loading/>
              }
              { !this.props.isFetching &&
              <Row>
                { this.props.account.prereqs.map((r, i)=>
                <Column small={12} key={i}>
                  <PrerequisiteItem 
                    item={r}
                    startCourse={this.props.startCourse}
                  />
                </Column>
                )}
              </Row>
              }
          </div>
        )
    }
}


function mapStateToProps(state) {
  const {account} = state;
  return {
    account: account,
    isFetching: account.isFetching
  };
}

export default connect(mapStateToProps)(PrerequisiteCourses);
