import React, { Component } from 'react';
import {Row, Column, Button} from 'react-foundation';
import { FiLock } from "react-icons/fi";
export default class Practicals extends Component {
	constructor() {
		super();
		this.state = {			
		}
	}

	render() {
		return (
			<div>					
				<Row className="practical">
					<Column small={6}>
						<Row>
							<Column small={12} className="text-left practical-cell">
								<div style={{opacity:.5}}>Cheer Building Level 1 Practical <FiLock/> </div>
							</Column>
						</Row>
					</Column>
					<Column small={6}>
						<Row>
							<Column small={12} className="text-left">
								Each practical session, no matter how many tests you're taking, costs $25. We recommend scheduling as many tests as you can per session.
							</Column>
							<Column small={12} className="text-right">
								<Button disabled className="cell-button pracitcal-button">Schedule a Practical Session</Button>
							</Column>
						</Row>
					</Column>
				</Row>								
			</div>
		)
	}
}

