import React from 'react';
import Loading from '../Loading';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {Row, Column, Progress, Button} from 'react-foundation';
import {
  fetchPracticalTest,
  gradePracticalTest
} from '../../actions/practicalTest';
import CollapsibleGroup from '../CollapsibleGroup';

class PracticalTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minScore: .8, // Min score to pass test
      index: 0,
      providedAnswers: [],
      notes:''
    }

    this.props.fetchPracticalTest(this.props.match.params.testId);
  }

  handleAnswer = (index, grade) => {
    var newAnswers = [];
    for(var i=0;i<this.state.providedAnswers.length;i++) {
      if(this.state.providedAnswers[i].index !== index)
        newAnswers.push(this.state.providedAnswers[i]);
    }
    newAnswers.push({
      index: index,
      grade: grade
    });
    this.setState({providedAnswers: newAnswers});
  }

  isChecked = (index, grade) => {
    for(var i=0;i<this.state.providedAnswers.length;i++) {
      if(this.state.providedAnswers[i].index == index) {
        if(this.state.providedAnswers[i].grade == grade)
          return true;
      }
    }
    return false;
  }

  gradePractical = () => {
    var answers = [];
    for(var i = 0;i<this.props.practical.questions.length;i++) {
      for(var j = 0;j<this.state.providedAnswers.length;j++) {
        if(this.state.providedAnswers[j].index == i) {
          answers.push({
            question: this.props.practical.questions[i].prompt,
            grade: parseInt(this.state.providedAnswers[j].grade)
          });
        }
      }
    }
    this.props.gradePracticalTest(this.props.match.params.testId, answers, this.state.notes);
  }

  render() {
    if(this.props.isFetching) {
      return(<Loading/>);
    }
    return(
      <Row>
        <Column small={12}>
          <div className="breadcrumb">
            <Link to="/">{"< "}&nbsp; Back to Dashboard</Link>
          </div>
          <div className="card practical-test">
             <Row className="card-row">
                <Column small={12}>
                  <h1 className="lesson-title">Practical Test</h1>
                  <h2>{this.props.practical.name}</h2>
                </Column>
              </Row>
             <Row className="card-row">
              { this.props.isGrading &&
                <div className="text-center" style={{width:'100%'}}>
                  <h1>Grading...</h1>
                </div>
              }
              { this.props.grade && !this.props.isGrading &&
                <div className="text-center" style={{width:'100%'}}>
                  <h1 className="practical-grade">
                    Grade 
                    <span className={this.props.grade >= 80 ? 'success' : 'danger'}>
                      {" "}{this.props.grade}%
                    </span>
                  </h1>
                  <Button><a href="/">Back to Dashboard</a></Button>
                </div>
              }
              { !this.props.grade && !this.props.isGrading &&  this.props.practical.questions &&
                <div style={{width:'100%'}}>
                  { this.props.practical.questions.map((question, index)=>

                    <CollapsibleGroup key={index} header={(index+1) + ".) " + question.prompt}>
                      <Row> 
                        <Column small={6} dangerouslySetInnerHTML={{__html: question.guide}} />
                       <Column small={6}>
                          <h3>Evaluation</h3>
                          <label>
                            <input value="1" type="radio" checked={this.isChecked(index, 1)} onChange={(e)=>{this.handleAnswer(index,e.target.value);}} />
                            {" 1 "}
                          </label>
                          <label>
                            <input value="2" type="radio" checked={this.isChecked(index, 2)} onChange={(e)=>{this.handleAnswer(index,e.target.value);}} />
                            {" 2 "}
                          </label>
                          <label>
                            <input value="3" type="radio" checked={this.isChecked(index, 3)} onChange={(e)=>{this.handleAnswer(index,e.target.value);}} />
                            {" 3 "}
                          </label>
                          <label>
                            <input value="4" type="radio" checked={this.isChecked(index, 4)} onChange={(e)=>{this.handleAnswer(index,e.target.value);}} />
                            {" 4 "}
                          </label>
                          <label>
                            <input value="5" type="radio" checked={this.isChecked(index, 5)} onChange={(e)=>{this.handleAnswer(index,e.target.value);}} />
                            {" 5 "}
                          </label>
                        </Column>
                      </Row>
                    </CollapsibleGroup>
                  )}
                  <CollapsibleGroup header={"Notes"}>
                    <textarea rows="8" onChange={ (e)=>{ this.setState({notes:e.target.value }); }} value={this.state.notes}></textarea>
                  </CollapsibleGroup>

                  <Column small={12} className="text-center">
                    {this.state.providedAnswers.length < this.props.practical.questions.length &&
                      <Button disabled className="card-button">Grade Practical</Button>
                    }
                    {this.state.providedAnswers.length >= this.props.practical.questions.length &&
                      <Button onClick={this.gradePractical} className="card-button">Grade Practical</Button>
                    }
                  </Column>
                </div>
              }
            </Row>
          </div>
        </Column>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  const {practicalTest} = state;
  return {
    isFetching: practicalTest.isFetching,
    isGrading: practicalTest.isGrading,
    practical: practicalTest.practicalTest,
    grade: practicalTest.grade
  };
}

const mapDispatchToProps = {
  fetchPracticalTest,
  gradePracticalTest
};
export default connect(mapStateToProps, mapDispatchToProps)(PracticalTest);
