import $ from "jquery";
import {fetchAccount} from "./account";

export const CART_ADD = 'CART_ADD';
export const CART_REMOVE = 'CART_REMOVE';
export const CLOSE_CART_MODAL = "CLOSE_CART_MODAL";

export const ORDER_STARTED = "ORDER_STARTED";
export const ORDER_FINISHED = "ORDER_FINISHED";
export const ORDER_ERROR = "ORDER_ERROR";

export const CONFIRMATION_VIEWED = "CONFIRMATION_VIEWED";

export const addItemToCart = (cart, item) => {
  return dispatch => {
    var newCart = {
      items: [],
      total: 0
    };

    for(var i=0;i<cart.items.length;i++) {
      if(cart.items[i].id !== item.id) {
       newCart.items.push(cart.items[i]);
       newCart.total += cart.items[i].price;
      }
    }
    newCart.items.push(item);
    newCart.total += item.price;

    dispatch({
      type:  CART_ADD,
      data: newCart
    });
  }
}

export const addItemsToCart = (cart, items) => {
  return dispatch => {
    var newCart = {
      items: [],
      total: 0
    };

    for(var i=0;i<cart.items.length;i++) {
      var found = false;
      for(var j=0;j<items.length;j++) {

        if(cart.items[i].id === items[j].id) {
          found = true;
          break;
        }
      }

      if(!found) {
         newCart.items.push(cart.items[i]);
         newCart.total += cart.items[i].price;
      }
    }

    for(var j=0;j<items.length;j++) {
      newCart.items.push(items[j]);
      newCart.total += items[j].price;
    }

    dispatch({
      type:  CART_ADD,
      data: newCart
    });
  }
}

export const removeItemFromCart = (cart, itemId) => {
  return dispatch => {
    var newCart = [];
    for(var i=0;i<cart.items.length;i++) {
      if(cart.items[i].id !== itemId) {
        newCart.push(cart[i]);
      }
    }

    dispatch({
      type:  CART_REMOVE,
      data: newCart
    });
  }
}

export const closeCartModal = () => {
  return dispatch => {
    dispatch({
      type:  CLOSE_CART_MODAL,
    });
  }
}

export const placeOrder = (items, address, city, state, zip, cc_number, cc_exp, cc_cvc) => {
  return dispatch => {
    dispatch({
      type:  ORDER_STARTED
    });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/order'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'post',
        data: {
          items,
          address,
          city,
          state,
          zip,
          cc_number,
          cc_exp,
          cc_cvc,
        }
    } ).done( ( response ) => {
      dispatch({
        type:  ORDER_FINISHED,
        data: response.data,
        lastOrder: {
          items: items,
          total: response.data.total,
          last4: response.data.last4,
          date: response.date
        }
      });
      // Re-load account
      dispatch(fetchAccount());
    } ).fail((response)=> {
      dispatch({
        type:  ORDER_ERROR,
        error: response.responseJSON.message
      });
    });
  }
}

export const triggerConfirmationView = () => {
  return dispatch => {
      dispatch({
      type:  CONFIRMATION_VIEWED
    });
  }
}
