import React from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {fetchWrittenTest, gradeWrittenTest} from '../actions/writtenTest';
import {Row, Column, Progress, Button} from 'react-foundation';

class ScormPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'url': this.props.url,
      'cmi.core.student_id': this.props.member_id,
      'cmi.core.student_name': this.props.account.name.last + ", " + this.props.account.name.first,
    }

    window.API = {
      LMSInitialize: () => {
        console.log("SCORM", "initialized");
        return true;
      },
      LMSFinish: () => {
        console.log("SCORM", "finish");
        return true;
      },
      LMSGetValue: (element) => {
        console.log("SCORM", "getValue", element);
        if(this.state[element])
          return this.state[element];
        else
          return '';
      },
      LMSSetValue: (element, value) => {
        if(element == "cmi.core.lesson_status" && value == "completed") {
          this.props.handleOnCompletion(this.state['cmi.core.score.raw']);
        }
        console.log("SCORM", "setValue", element, value);
        this.setState({[element]:value});
        return true;
      },
      LMSCommit: () => {
        console.log("SCORM", "commit");
        return true;
      },
      LMSGetLastError: () => {
        console.log("SCORM", "getLastError");
        return '';
      },
      LMSGetErrorString: (errorCode) => {
        console.log("SCORM", "getErrorString", errorCode);
        return '';
      },
      LMSGetDiagnostic: (errorCode) => {
        console.log("SCORM", "getDiagnostic", errorCode);
        return '';
      },
    };
  }

  autoComplete = (url) => {
      switch(url) {
        case  "/lms/d2l/1/player.html": {
          this.setState({
            'url': url += "?v=1",
            'cmi.core.score.raw': 100,
            'cmi.suspend_data': '8|#|4|#|19|#|1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1|#|2|#|1,1,1,1,1,1|#|100|#|216',
            'cmi.core.lesson_status': ''
          });
          break;
        }
        case  "/lms/d2l/2/player.html": {
          this.setState({
            'url': url += "?v=1",
            'cmi.core.score.raw': 100,
            'cmi.suspend_data': '5|#|4|#|18|#|1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1|#|0|#|0,0,0,0,0|#|90|#|204',
            'cmi.core.lesson_status': ''
          });
          break;
        }
        case  "/lms/d2l/3/player.html": {
          this.setState({
            'url': url += "?v=1",
            'cmi.core.score.raw': 100,
            'cmi.suspend_data': '6|#|4|#|17|#|1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1|#|0|#|0,0,0,0,0|#|100|#|208',
            'cmi.core.lesson_status': ''
          });
          break;
        }
        case  "/lms/d2l/4/player.html": {
          this.setState({
            'url': url += "?v=1",
            'cmi.core.score.raw': 100,
            'cmi.suspend_data': '20|#|8|#|49|#|1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1|#|0|#|0,0,0,0,0,0,0,0,0,0,0,0,0,0,0|#|100|#|10',
            'cmi.core.lesson_status': ''
          });
          break;
        }
        case  "/lms/d2l/5/player.html": {
          this.setState({
            'url': url += "?v=1",
            'cmi.core.score.raw': 100,
            'cmi.suspend_data': '3|#|9|#|45|#|1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1|#|0|#|0,0,0,0,0,0,0,0,0,0,0,0,0,0,0|#|100|#|5',
            'cmi.core.lesson_status': ''
          });
          break;
        }
        case  "/lms/d2l/6/player.html": {
          this.setState({
            'url': url += "?v=1",
            'cmi.core.score.raw': 100,
            'cmi.suspend_data': '11|#|3|#|16|#|1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1|#|0|#|0,0,0,0|#|100|#|14',
            'cmi.core.lesson_status': ''
          });
          break;
        }
        default: {
          this.setState({
              'url': url += "?v=1",
              'cmi.core.score.raw': 0,
              'cmi.suspend_data': '',
              'cmi.core.lesson_status': ''
          });
        }
      }

  }

  componentDidMount() {
    if(this.props.autoComplete === true) {
      this.autoComplete(this.props.url);
      }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.url != this.props.url) {
      if(this.props.autoComplete === true) {
        this.autoComplete(nextProps.url);
      }
      else {
        this.setState({
            'cmi.core.score.raw': 0,
            'cmi.suspend_data': ''
        });
      }
    }
  }

  render() {
    if(this.state.isLoading) return(<div>...</div>)
    return <iframe src={this.props.url } style={{width:'100%',height:'600px'}}></iframe>;
  }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    account: account
  };
}

export default connect(mapStateToProps)(ScormPlayer);
