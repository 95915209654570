import React, { Component } from 'react';
import InProgressItem from './common/inProgressItem';

export default class InProgress extends Component {
	constructor() {
		super();
		this.state = {
			activeIndex: 1
		}
	}

	render() {
		return (
			<div className="inprogress-tab-contents">				
				<InProgressItem />
			</div>
		)
	}
}

