import React from 'react';
import {Button} from 'react-foundation';
import {Row, Column, Progress} from 'react-foundation';
import { FiAlertCircle } from "react-icons/fi";
import medal from "../../images/medal.png";
import icon_chevron from "../../images/icons/Chevron.svg";
import TabView from '../tabView';
import InProgressTable from '../InProgressTable';
import AvailablePracticals from '../AvailablePracticals';
import CredentialHistory from '../CredentialHistory';
import CollapsibleGroup from '../CollapsibleGroup';
import RequirementTable from '../RequirementTable';
import PreReqList from '../PreReqList';

import {addItemsToCart, addItemToCart} from '../../actions/commerce';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import NextStepButton from '../progressCard/NextStepButton';

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.expanded
    }
  }

  hasLevels = () => {
    return this.props.course.credentials.length > 1;
  }

  getCredentialedLevels = () => { 
    if(!this.hasLevels()) return '';

    if(this.props.course.name == "Cheer Building Level 7") {
      return "Level 7";
    }
    else if(this.props.course.max_level <= 0) {
      return "Levels 0";
    }
    else if(this.props.course.max_level == 1) {
      return "Levels 1";
    }
    else {
      return "Levels 1-" + Math.min(6,this.props.course.max_level);
    }
  }

  nextLevel = () => {
    var maxLevels = this.props.course.max_level;
    var totalLevels = this.props.course.credentials.filter((c, index)=>{
      return index<=5
    }).length;
    if(maxLevels >= totalLevels) return false
    else return maxLevels +1;
  }

  addNextLevelToCart = () => {
    var course = this.props.course.credentials[this.nextLevel() -1];
    var writtenTest = null;

    for(var i=0;i<course.reqs.length;i++) {
      if(course.reqs[i]['type'] === 'written') {
        this.props.addItemToCart(
          this.props.cart,
          {
          "id":    course.reqs[i].id,
          "name" : course.reqs[i].name,
          "price": course.reqs[i].price,
          "type": "course"
          }
        );
        break;
      }
    }
  }

  renewAll = () => {
    console.log(this.props.course);
    if(this.props.course.name == "Cheer Building Level 7") {
      this.props.addItemToCart(
        this.props.cart, {
          "id":    this.props.course.credentials[0].reqs[0].id,
          "name" : "Cheer Building Level 7",
          "price": this.props.course.credentials[0].credential_price,
          "type": "course"
        }
      );
    }
    else {
      var items = [];

      for(var j=0;j< Math.floor(6,this.props.course.max_level);j++) {
        var course = this.props.course.credentials[j];
        for(var i=0;i<course.reqs.length;i++) {
          if(course.reqs[i]['type'] === 'written' && !course.reqs[i]['purchased']) {
           items.push({
              "id":    course.reqs[i].id,
              "name" : course.reqs[i].name,
              "price": course.reqs[i].price,
              "type": "course"
            });
          }
        }
      }

      this.props.addItemsToCart(
        this.props.cart, items
      );
    }
  }

  isRenewing = () => {
    for(var j=0;j<this.props.course.max_level;j++) {
      var course = this.props.course.credentials[j];
      if(typeof course !== 'undefined' && typeof course.reqs !== 'undefined') {
        for(var i=0;i<course.reqs.length;i++) {
          if(course.reqs[i]['purchased']) {
            return true;
          }
        }
      }
    }
    return false;
  }


  render () {
      return (
        <Column className="credentialing-card" large={this.state.isOpen? 12 : 4} medium={this.state.isOpen? 12: 6} small={12}>
          <div className="card">
              { !this.state.isOpen &&
             <Row className="card-row">
                <Column small={10}>
                  <Row>
                    <Column small={12}>
                      <div className="card-title">{this.props.course.name}</div>
                    </Column>
                    <Column small={12}>
                      <div className="card-expiration">
                        <FiAlertCircle/>
                        <span>
                          EXPIRES {this.props.course.expiration}
                        </span>
                      </div>
                    </Column>
                  </Row>
                </Column>
                <Column small={2} className="text-center">
                    <img src={medal} className="medal" alt="medal"/>
                </Column>
              </Row>
              }

              { this.state.isOpen &&
             <Row className="card-row">
                <Column small={7}>
                  <Row>
                    <Column small={12}>
                      <div className="card-title">{this.props.course.name}</div>
                    </Column>
                    <Column small={12}>
                      <div className="card-expiration">
                        <FiAlertCircle/>
                        <span>
                          EXPIRES {this.props.course.expiration}
                        </span>
                      </div>
                    </Column>
                  </Row>
                </Column>
                <Column small={3}>
                  {/*
                  <Row>
                    <Column small={12} className="text-center">
                      <div className="card-title percentage" >
                        <span>
                          14% Complete
                          <Progress min={0} max={100} value={14} className="progress-bar" />
                          <Row>
                            <Column small={4} className="percentage-laebel text-left">0</Column>
                            <Column small={4} className="percentage-laebel text-center">50</Column>
                            <Column small={4} className="percentage-laebel text-right">100</Column>
                          </Row>
                        </span>                            
                      </div>
                    </Column>
                  </Row>
                  */}
                </Column>
                <Column small={2} className="text-center">
                    <img src={medal} className="medal" alt="medal"/>
                </Column>
              </Row>
              }

              <Row className="card-row">
                <Column small={12}>
                    <div className="card-sub-title">{'Credentialed '+this.getCredentialedLevels()}</div>
                </Column>
                <Column medium={10} small={12}>
                 {/*
                  // {!this.isRenewing() &&
                  //   <Button onClick={this.renewAll} className="card-button">Renew</Button>
                  // }
                  */}
                  { /*this.nextLevel() && !this.props.course.credentials[this.nextLevel()-1].reqs[0].purchased &&
                    <Button onClick={(e)=>{
                      this.addNextLevelToCart();
                    }} className="card-button">Add Level {this.nextLevel()}</Button>
                  */}
                    {/*<NextStepButton course={this.props.course}/>*/}
                    <br/>
                </Column>
                <Column small={12} medium={2}>
                  <Button className="chevron-button mobile-center" onClick={()=>{
                    this.setState({isOpen: !this.state.isOpen})
                  }}>
                    <img src={icon_chevron} alt="Chevron"
                      className={this.state.isOpen ? "chevron-reverse":null}
                    />
                  </Button>
                </Column>
              </Row>

              { this.state.isOpen &&
                <TabView>
                  <div title="Next Steps">
                    <CollapsibleGroup header={"Renew " + this.getCredentialedLevels()} isCollapsed={false}>
                      <RequirementTable 
                        course={this.props.course}
                        filter='renewal'
                        allowRenewal={true}
                      />
                    </CollapsibleGroup>
                    <br/>
                    { this.nextLevel() &&
                    <CollapsibleGroup header={"Upgrade Levels"} isCollapsed={false}>
                      <RequirementTable 
                        course={this.props.course}
                        filter='upgrade'
                        allowRenewal={false}
                      />
                    </CollapsibleGroup>
                    }
                    <br/>
                    <AvailablePracticals
                      course={this.props.course}
                    />
                  </div>
                  <div title="In Progress">
                    {/*
                    <InProgressTable
                      course={this.props.course}
                    />
                    */}
                      {this.props.course.pre_reqs &&
                          <PreReqList
                            course={this.props.course}
                          />
                      }
                      <RequirementTable 
                        course={this.props.course}
                        filter='renewal'
                        allowRenewal={true}
                      />
                      <RequirementTable 
                        course={this.props.course}
                        filter='upgrade'
                        allowRenewal={false}
                        hideHeader={true}
                      />
                    <br/>
                    <br/>
                    <AvailablePracticals
                      course={this.props.course}
                    />
                  </div>
                  <div title={this.props.course.name + " History"}>
                    <CredentialHistory
                      course={this.props.course}
                    />
                  </div>
                </TabView>

              }
          </div>
        </Column>
        )
    }
}

function mapStateToProps(state) {
  const {account, commerce} = state;
  return {
    cart: commerce.cart,
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

const mapDispatchToProps = {
  addItemsToCart: addItemsToCart,
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Card));
