import React from 'react'
import { Link } from 'react-router-dom';
import {Row, Column, Button} from 'react-foundation';
import {formatTimestampWithTime} from '../../util/credentials';

class ScheduledPracticals extends React.Component {
  render(){
    if(this.props.practicals.length ==0 ){
      return(
        <h2>You have no scheduled practicals at this time.</h2>
      );
    }
    return (
      <div className="scheduled-practicals-table data-table">
        <header>
          <Row>
            <Column small={3}>
              Date
            </Column>
            <Column small={3}>
              Test
            </Column>
            <Column small={3}>
              Testee
            </Column>
            <Column small={3}>
              Action
            </Column>
          </Row>
        </header>
        { this.props.practicals.map(practical=>
          <Row key={practical.id}>
            <Column small={3}>
              {formatTimestampWithTime(practical.date)}
            </Column>
            <Column small={3}>
              {practical.course.name}
            </Column>
            <Column small={3}>
              {practical.testee.name}
            </Column>
            <Column small={3}>
              <Button className="card-button"><Link to={"/practical/"+practical.id}>Start Practical</Link></Button>
            </Column>
          </Row>
        )}
      </div>
    )
  }
}
export default ScheduledPracticals;
