import $ from "jquery";

import {fetchAccount} from "./account";

export const WRITTEN_TEST_RESET         = 'WRITTEN_TEST_RESET';

export const WRITTEN_TEST_FETCH_START   = 'WRITTEN_TEST_FETCH_START';
export const WRITTEN_TEST_FETCH_SUCCESS = 'WRITTEN_TEST_FETCH_SUCCESS';
export const WRITTEN_TEST_FETCH_FAILURE = 'WRITTEN_TEST_FETCH_FAILURE';

export const WRITTEN_TEST_GRADE_START   = 'WRITTEN_TEST_GRADE_START';
export const WRITTEN_TEST_GRADE_SUCCESS = 'WRITTEN_TEST_GRADE_SUCCESS';
export const WRITTEN_TEST_GRADE_FAILURE = 'WRITTEN_TEST_GRADE_FAILURE';

export const WRITTEN_TEST_SAVE_PROGRESS_START   = 'WRITTEN_TEST_SAVE_PROGRESS_START';
export const WRITTEN_TEST_SAVE_PROGRESS_SUCCESS = 'WRITTEN_TEST_SAVE_PROGRESS_SUCCESS';
export const WRITTEN_TEST_SAVE_PROGRESS_FAILURE = 'WRITTEN_TEST_SAVE_PROGRESS_FAILURE';

export const MARK_CUSTOM_PREREQ_COMPLETE = 'MARK_CUSTOM_PREREQ_COMPLETE';


export const  fetchWrittenTest = (id, isCovidSpecial = false) => {
  return dispatch => {
      dispatch({
        type:  WRITTEN_TEST_RESET,
      });

      dispatch({
        type:  WRITTEN_TEST_FETCH_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/written/' + id
          + '?_wpnonce=' + window.WP_API_Settings.nonce
          + '&covid='+isCovidSpecial,
        method: 'GET'
    }).done( ( response ) => {

      dispatch({
        type:  WRITTEN_TEST_FETCH_SUCCESS,
        data: response.data
      });

    } ).fail((e)=> {
      var eMsg = e.responseJSON && e.responseJSON.message ?
        e.responseJSON.message :
        'Could not find course';

      dispatch({
        type:  WRITTEN_TEST_FETCH_FAILURE,
        error: eMsg
      });
    });
  }
}

export const  saveWrittenTestProgress = (id, answers, isCovidSpecial = false) => {
  return dispatch => {

      dispatch({
        type:  WRITTEN_TEST_SAVE_PROGRESS_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/written/update' + id
          + '?_wpnonce=' + window.WP_API_Settings.nonce
          + '&covid='+isCovidSpecial,
        method: 'POST',
        data: {
          answers: answers
        }
    }).done( ( response ) => {

      dispatch({
        type:  WRITTEN_TEST_SAVE_PROGRESS_SUCCESS,
        grade: response.data
      });

    } ).fail(()=> {

      dispatch({
        type:  WRITTEN_TEST_SAVE_PROGRESS_FAILURE,
        error: "There was an error while saving your progress."
      });

    });
  }
}

export const  gradeWrittenTest = (id, answers, isCovidSpecial = false) => {
  return dispatch => {

      dispatch({
        type:  WRITTEN_TEST_GRADE_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/written/grade' + id
          + '?_wpnonce=' + window.WP_API_Settings.nonce
          + '&covid='+isCovidSpecial,
        method: 'POST',
        data: {
          answers: answers
        }
    }).done( ( response ) => {

      dispatch({
        type:  WRITTEN_TEST_GRADE_SUCCESS,
        grade: response.data
      });

      dispatch(fetchAccount());

    } ).fail(()=> {

      dispatch({
        type:  WRITTEN_TEST_GRADE_FAILURE,
        error: "There was an error while grading the course."
      });

    });
  }
}

export const completeD2l = () => {
  return dispatch => {
     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/d2l/grade'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'POST',
        data: {}
    }).done( ( response ) => {
      dispatch(fetchAccount());
    } ).fail(()=> {
    });
  }
}
