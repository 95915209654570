import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import {Row, Column, Button} from 'react-foundation';
import { FaTachometerAlt, FaRandom } from 'react-icons/fa';

class AdminMenu extends React.Component {

  showMenu = () => {
    if(this.props.account.role == 'administrator')
      return true;
    if(this.props.account.switch_url && this.props.account.switch_url !== '')
      return true;

    return false;
  }

  render() {
    if(!this.showMenu()) {
      return(<div></div>);
    }

    return(
      <div id="admin-menu">
        <div className="inner-wrap">
          <Row>
            <Column small={6} medium={4}>
              {this.props.account.switch_url && this.props.account.switch_url !== '' &&
                <a href={this.props.account.switch_url}>
                  <FaRandom/>
                  {" "}
                  Swith Back to {this.props.account.switch_user}
                </a>
              }
              {(!this.props.account.switch_url || this.props.account.switch_url == '') &&
                <a href="/wp-admin">
                  <FaTachometerAlt/>
                  {" "}
                  Dashboard
                </a>
              }
            </Column>
            <Column small={6} medium={8} className="text-right">
              <a href="#" onClick={this.props.logout}>Logout</a>
            </Column>
          </Row>
        </div>
      </div>
    );
  }


}


function mapStateToProps(state) {
  const {account} = state;

  return {
    account: account,
  };
}

export default connect(mapStateToProps)(AdminMenu);
