import React from 'react';
import DesktopHeader from "./Desktop";
import MobileHeader from "./Mobile";
import {logout} from "../../actions/authentication";
import {fetchAccount} from "../../actions/account";
import { connect } from 'react-redux';
import AdminMenu from './AdminMenu';
import ExpirationWarning from './ExpirationWarning';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchAccount();
    this.state = {
      isMobile: false
    }
  }
  componentDidMount() {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }

  checkWidth = () => {
    if(window.innerWidth > 1024) {
      this.setState({isMobile: false});
    }
    else {
      this.setState({isMobile: true});
    }
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.logout();
  }

  render () {
    return (
      <div>
        <AdminMenu logout={this.handleLogout}/>
        {this.state.isMobile &&
          <MobileHeader logout={this.handleLogout}/>
        }
        {!this.state.isMobile &&
          <DesktopHeader 
            logout={this.handleLogout} 
            account={this.props.account}
          />
        }
        <ExpirationWarning/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {authentication, account} = state;
  return {
    isLoading: authentication.isLoading,
    isAuthenticated : authentication.isAuthenticated,
    account: account
  };
}

const mapDispatchToProps = {
  fetchAccount: fetchAccount,
  logout: logout
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
