import React from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {fetchWrittenTest, gradeWrittenTest, saveWrittenTestProgress} from '../actions/writtenTest';
import {Row, Column, Progress, Button} from 'react-foundation';
import VideoEmbed from './VideoEmbed';
const replaceAll = require('string.prototype.replaceall');


class WrittenTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minScore: .7, // Min score to pass test
      index: 0,
      totalQuestions: 0,
      providedAnswers: [],
      isStudyAtHomeCourse: (window.location.pathname.substring(1,14)=='study-at-home')

    }
    this.props.fetchWrittenTest(this.props.match.params.writtenTestId, this.state.isStudyAtHomeCourse);
    this.containerRef = React.createRef();

    replaceAll.shim();
  }

  getLessonCategory = () => {
    return '';
  }

  getLessonName = () => {
    return this.props.writtenTest.name;
  }

  getCurrentPage = () => {
    if(typeof this.props.writtenTest.pages[this.state.index-1] !== "undefined")
      return this.props.writtenTest.pages[this.state.index-1];
    else 
      return {type:'n/a'};
  }

  componentWillReceiveProps(nextProps){
    // Parse saved answers
    if(this.props.isFetching && !nextProps.isFetching) {
      let providedAnswers = [];
      let totalQuestions = 0;
      for(var i=0;i<nextProps.writtenTest.pages.length;i++) {
        if(typeof nextProps.writtenTest.pages[i].questions == 'object') {
          totalQuestions += nextProps.writtenTest.pages[i].questions.length;
          for(var j=0;j<nextProps.writtenTest.pages[i].questions.length;j++) {
            let question = nextProps.writtenTest.pages[i].questions[j];
            if(question.answer != '') {
              providedAnswers.push({
                sectionId: question.sectionId,
                questionId: question.id,
                answer: question.answer
              });
            }
          }
        }
      }
      this.setState({
        totalQuestions: totalQuestions,
        providedAnswers:providedAnswers
      });
    }
  }

  getProgress = () => {
    if(this.state.providedAnswers.length == 0)
      return 0;
    else return Math.round(this.state.providedAnswers.length/this.state.totalQuestions*100);
  }

  startCourse = (e) => {
    e.preventDefault();
    this.setState({
      index: 1
    });
  }

  canContinue = () => {
    var currentPage = this.props.writtenTest.pages[this.state.index-1];
    if(currentPage.type == "video") return true;

    var isComplete = true;
    for(var i=0;i < currentPage.questions.length;i++) {
      var found = false;
      for(var j=0; j < this.state.providedAnswers.length; j++) {
        if(this.state.providedAnswers[j]['questionId'] == currentPage.questions[i]['id']){
          found = true;
          break
        }
      }
      if(!found) isComplete = false;
    }
    return isComplete;
    // return this.props.writtenTest.pages[this.state.index-1].isComplete;
  }
  goBack = () => {
    this.setState({index: this.state.index-1});
  }

  next = () => {

    window.scrollTo(0, this.containerRef.current.offsetTop);

    this.getCurrentPage().isComplete = true;
    var newIndex = this.state.index+1;

    // Time to grade!
    if(newIndex > this.props.writtenTest.pages.length) {
      this.props.gradeWrittenTest(
        this.props.writtenTest.id,
        this.state.providedAnswers,
        this.state.isStudyAtHomeCourse
      );
    }
    else if(this.state.providedAnswers.length > 0) {
      this.props.saveWrittenTestProgress(
        this.props.writtenTest.id,
        this.state.providedAnswers,
        this.state.isStudyAtHomeCourse
      );
    }
    this.setState({index: newIndex});
  }


  handleQuestionAnswer = (e, sectionId, questionId, answer) => {
    var newAnswers = this.state.providedAnswers;

    var found = false;
    for(var i=0;i<newAnswers.length;i++) {
      if(newAnswers[i].questionId == questionId && newAnswers[i].sectionId == sectionId) {
        newAnswers[i].answer = answer;
        found = true;
      }
    }
    if(!found) newAnswers.push({
      sectionId: sectionId,
      questionId: questionId,
      answer: answer
    });
    this.setState({providedAnswers: newAnswers});
  }

  retakeCourse = () => {
    this.props.fetchWrittenTest(this.props.match.params.writtenTestId, this.state.isStudyAtHomeCourse);
    this.setState({
      index: 0,
      providedAnswers: []
    });
  }

  render() {
        let disabledCourses = ['109062','109060'];

        console.log("Course ID", this.props.match.params.writtenTestId);
        if(disabledCourses.includes(this.props.match.params.writtenTestId)) {

          return(
            <Row>
              <div className="breadcrumb">
                <Link to={'/'}>{"< "}&nbsp; Back to Dashboard</Link>
              </div>
              <div className="card written-test">
                <h1>System Updating</h1>
                <p>The Level 7 testing is currently being updated and is not available.  If you need to test in level 7 please email credentialing@usasf.net.  As soon as the level is updated and back open we will notify you.</p>
              </div>
            </Row>
          );
        }


    if(this.props.writtenTest.isFetching)
      return (<Loading/>);

    if(this.props.writtenTest.error != null) {
      return(
        <Row>
          <div className="breadcrumb">
            {this.state.isStudyAtHomeCourse &&
              <Link to={'/study-at-home'}>{"< "}&nbsp; Back to Course List</Link>
            }
            {!this.state.isStudyAtHomeCourse &&
              <Link to={'/'}>{"< "}&nbsp; Back to Dashboard</Link>
            }
          </div>
          <div className="card written-test">
            <h1>Error: {this.props.writtenTest.error}</h1>
          </div>
        </Row>
      );
    }
      
    return(
      <Row>
        <Column small={12}>
          <div className="breadcrumb">
            {this.state.isStudyAtHomeCourse &&
              <Link to={'/study-at-home'}>{"< "}&nbsp; Back to Course List</Link>
            }

            {!this.state.isStudyAtHomeCourse &&
              <Link to={'/'}>{"< "}&nbsp; Back to Dashboard</Link>
            }
          </div>
          <div className="card written-test" ref={this.containerRef}>
             <Row className="card-row" className="hide-for-small-only">
                <Column medium={9} small={12}>
                  <h1 className="lesson-title" dangerouslySetInnerHTML={{__html: this.getLessonName()}}/>
                </Column>
                <Column medium={3} className="hide-for-small-only">
                  <Row>
                    <Column small={12} className="text-right">
                      <div className="card-title percentage" >
                        <span>
                          {this.getProgress()}% Complete
                          <Progress min={0} max={100} value={this.getProgress()} className="progress-bar" />
                          <Row>
                            <Column small={4} className="percentage-laebel text-left">0</Column>
                            <Column small={4} className="percentage-laebel text-center">50</Column>
                            <Column small={4} className="percentage-laebel text-right">100</Column>
                          </Row>
                        </span>
                      </div>
                    </Column>
                  </Row>
                </Column>
              </Row>



              { this.state.index == 0 &&
                <div className="lesson-content">
                  <h2>Course + Written Test</h2>
                  <div className="lesson-overview">
                    <Row>
                      {this.props.writtenTest.description != null && this.props.writtenTest.description !== '' &&
                        <Column small={12} medium={6}>
                          <p>{this.props.writtenTest.description}</p>
                        </Column>
                      }
                      <Column small={12} medium={6}>
                        <h3>Breakdown:</h3>
                        <ul className="breakdown">
                        { this.props.writtenTest.sections.map( (section) =>
                          <li key={section.id}>
                            {section.name.replaceAll("&amp;","&")} - {section.has_video ? 'Video and Quiz' : 'Quiz'}
                          </li>
                        )}
                        </ul>
                      </Column>
                    </Row>
                  </div>

                  <Button className="card-button" onClick={this.startCourse}>Start Course</Button>
                </div>
              }
              {/* Video */}
              { this.getCurrentPage().type === "video" &&
                <div className="lesson-content">
                  <Row>
                    <Column small={12}>
                      <h2>{this.getCurrentPage().section_name.replaceAll("&amp;","&")} - Video</h2>
                    </Column>
                  </Row>
                  <Row>
                    <Column small={12} large={2}/>
                    <Column small={12} large={8} className="text-center">
                      <VideoEmbed src={this.getCurrentPage().video}/>
                    </Column>
                  </Row>
                  <Row>
                    <Column small={6}>
                      <Button className="card-button" onClick={this.goBack}>Back</Button>
                    </Column>
                    <Column small={6} className="text-right">
                      { this.canContinue() &&
                        <Button className="card-button" onClick={this.next}>
                          Next
                        </Button>
                      }
                      { !this.canContinue() &&
                        <Button className="card-button" disabled onClick={this.next}>
                          Next
                        </Button>
                      }
                    </Column>
                  </Row>
                </div>
              }

              {/* Quiz */}
              { this.getCurrentPage().type === "quiz" &&
                <div className="lesson-content">
                  <Row>
                    <Column small={12}>
                      <h2>{this.props.writtenTest.pages[this.state.index-1].section_name.replaceAll("&amp;","&")} - Quiz</h2>
                    </Column>
                  </Row>
                  <Row style={{}}>
                    { this.getCurrentPage().questions.map( (q, index) =>
                      <Column small={12} medium={6} key={q.id} className="quiz-question">
                        <h3>{q.index+1}{".) "}{q.question}</h3>
                        <Row className="answer-group">
                        { q.answers.map( (answer, index) =>
                          <Column small={12} key={index}>
                            <label className="answer">
                              <input
                                type="radio"
                                name={q.id}
                                value={answer}
                                onChange={ (e)=>{
                                  this.handleQuestionAnswer(e, this.getCurrentPage().section_id, q.id, answer);
                                }}
                                checked={ (()=>{
                                  for(var j=0; j < this.state.providedAnswers.length; j++) {
                                    if(this.state.providedAnswers[j]['questionId'] == q.id){
                                      if(this.state.providedAnswers[j]['sectionId'] == this.getCurrentPage().section_id){
                                        if(this.state.providedAnswers[j]['answer'] == answer){
                                          return true;
                                        }
                                      }
                                    }
                                  }
                                  return false;
                                })()}
                              />

                              {" "}
                              {String.fromCharCode(65 + index)}.) {answer}
                            </label>
                          </Column>
                        )}
                        </Row>
                      </Column>
                    )}
                  </Row>
                  <Row>
                    <Column small={6}>
                      <Button className="card-button" onClick={this.goBack}>Back</Button>
                    </Column>
                    <Column small={6} className="text-right">
                      { this.canContinue() &&
                        <Button className="card-button" onClick={this.next}>
                          {this.state.index == this.props.writtenTest.pages.length &&
                            <span>Grade</span>
                          }
                          {this.state.index < this.props.writtenTest.pages.length &&
                            <span>Next</span>
                          }
                        </Button>
                      }
                      { !this.canContinue() &&
                        <Button className="card-button" style={{opacity:.5}} onClick={()=>{alert("Please answer all questions before proceeding.")}}>
                           {this.state.index == this.props.writtenTest.pages.length &&
                            <span>Grade</span>
                          }
                          {this.state.index < this.props.writtenTest.pages.length &&
                            <span>Next</span>
                          }
                        </Button>
                      }
                    </Column>
                  </Row>
                </div>
              }
              { this.state.index > this.props.writtenTest.pages.length &&
                <div className="lesson-content">
                  <Row>
                    <Column small={12} className="text-center">
                      <h2 style={{marginBottom:0}}>Your Grade:<br/>
                      { this.props.writtenTest.isGrading &&
                        <span className="grade">Grading..</span>
                      }
                      { !this.props.writtenTest.isGrading &&
                        <span className="grade">
                          { this.props.writtenTest.grade.grade < this.state.minScore && 
                            <span className="danger">
                              { Math.round(this.props.writtenTest.grade.grade*100) }%
                            </span>
                          }
                          { this.props.writtenTest.grade.grade >= this.state.minScore && 
                            <span className="success">
                              { Math.round(this.props.writtenTest.grade.grade*100) }%
                            </span>
                          }
                        </span>
                      }
                      </h2>
                      { !this.props.writtenTest.isGrading &&
                        <Row className="next-steps">
                          <Column small={12} className="text-center">
                            { this.props.writtenTest.grade.grade < this.state.minScore &&
                              <div className="retake">
                                <p className="retake">To pass this course, you need a minimum score of { Math.round(this.state.minScore*100) }%</p>
                                { /*
                                <p>Missed Questions:</p>
                                <ul className="missed-questions">
                                  {this.props.writtenTest.grade.incorrectAnswers.map( (a) =>
                                    <li>{a.question}</li>
                                  )}
                                </ul>
                                */}
                                <Button className="card-button" onClick={this.retakeCourse}>Retake Course</Button>
                              </div>
                            }
                            { this.props.writtenTest.grade.grade >= this.state.minScore &&
                              <div className="return-to-dash">
                                <p className="retake">Congratulations! You have successfully passed this course.</p>
                                <Button className="card-button" onClick={(e)=>{ this.props.history.push('/');}}>Return to Dasboard</Button>
                              </div>
                            }
                          </Column>
                        </Row>
                      }
                    </Column>
                  </Row>
                </div>
              }
          </div>
        </Column>
      </Row>
    )
  }

}

function mapStateToProps(state) {
  const {writtenTest} = state;
  return {
    isFetching: writtenTest.isFetching,
    writtenTest: writtenTest
  };
}

const mapDispatchToProps = {
  fetchWrittenTest: fetchWrittenTest,
  gradeWrittenTest: gradeWrittenTest,
  saveWrittenTestProgress: saveWrittenTestProgress
};
export default connect(mapStateToProps, mapDispatchToProps)(WrittenTest);
