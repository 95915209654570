import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {closeCartModal} from "../../actions/commerce";
import { FaTimes } from 'react-icons/fa';
import AddSuccess from '../../images/icons/add-success.png';

class AddToCartModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {topOffset:0};
  }
  closeModal = (e) => {
    e.preventDefault();
    this.props.closeCartModal();
  }

  getShortName = (name) =>{
    if(name.length > 30) {
        return name.substring(0,30)+"...";
    }
    return name;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.modalVisible) {
      this.setState({topOffset: window.scrollY+30});
    }
  }

  render () {
    if(!this.props.modalVisible) return (<div/>);

    return (
      <div>
        <div className="modal-overlay" onClick={this.closeModal}></div>
        <div className="modal-content" id="add-to-cart-modal" style={{top:this.state.topOffset}}>
          <a href="#close" className="close-modal" onClick={this.closeModal}><FaTimes/></a>
          <div className="inner-content">
            <img src={AddSuccess}/>
            <h1>Added to Cart!</h1>
            { this.props.cart.items.map( (item) =>
              <div className="item-row" key={item.id}>
                 <dt>
                  <span>{this.getShortName(item.name)}</span>
                </dt>
                 <dd>
                  <span>${item.price.toFixed(2)}</span>
                </dd>
              </div>
            )}
            <div style={{clear:'both'}} className="button-group">
              <Button className="card-button" onClick={this.closeModal}>
                <Link to="/" style={{color:'#fff'}}>
                    Back to Credentials
                </Link>
              </Button>
              <Button className="card-button">
                <Link to="/checkout" style={{color:'#fff'}}>
                Proceed to Checkout
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {commerce} = state;
  return {
    modalVisible: commerce.modalVisible,
    cart: commerce.cart
  };
}

const mapDispatchToProps = {
  closeCartModal: closeCartModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartModal);
