import {
  CART_ADD,
  CART_REMOVE,
  CLOSE_CART_MODAL,
  ORDER_STARTED,
  ORDER_FINISHED,
  ORDER_ERROR,
  CONFIRMATION_VIEWED
} from "../actions/commerce";

export const initialState = {
  modalVisible: false,
  orderProcessing: false,
  lastOrder: null,
  error: null,
  // lastOrder: {
  //   date: "01/01/19",
  //   items: [
  //   {
  //     id: '67',
  //     name: 'Test Course',
  //     price: 15
  //   }
  //   ],
  //   total: "$15.00",
  //   last4: 1234
  // },
  cart: {
    items: [],
    total: 0
  }
  // cart: {
  //   items: [
  //     {
  //       id: 'practical-session',
  //       name: 'Virtual Practical Session',
  //       price: 25,
  //       date: "Monday, July 7th",
  //       time: "5:00pm EST",
  //       type: 'practical-virtual',
  //       practicals: [1,2,3],
  //       practical_names: [
  //         'Building Written 1',
  //         'Building Written 2',
  //         'Tumbling Written 1',
  //       ]
  //     }
  //   ],
  //   total: 15
  // }
};


export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case CART_ADD: {
      return {
        ...state,
        modalVisible: true,
        cart: action.data
      }
    }
    case CART_REMOVE: {
      return {
        ...state,
        cart: action.data
      }
    }
    case CLOSE_CART_MODAL: {
      return {
        ...state,
        modalVisible: false,
      }
    }
    case ORDER_STARTED: {
      return {
        ...state,
        orderProcessing: true,
        error: null
      }
    }
    case ORDER_FINISHED: {
      return {
        ...state,
        orderProcessing: false,
        cart : {
          items: [],
          total: 0
        },
        lastOrder: action.lastOrder
      }
    }
    case ORDER_ERROR: {
      return {
        ...state,
        orderProcessing: false,
        error: action.error
      }
    }
    case CONFIRMATION_VIEWED: {
      return {
        ...state,
        lastOrder: null
      }
    }
    default:
      return state;
  }
}

