import React from 'react';
// import { Route, Switch, Link } from 'react-router' // react-router v4/v5
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Header from './components/header';
import Dashboard from './containers/Dashboard';
import About from './components/AboutUs';
import Login from './components/Login';
import WrittenTest from './components/WrittenTest';
import $ from "jquery";
import Checkout from './components/Checkout';
import Confirmation from './components/Confirmation';
import BottomArea from './components/bottomArea';
import DarknessToLight from './components/DarknessToLight';
import Pca from './components/Pca';
import SchedulePractical from './components/SchedulePractical';
import AllCredentialHistory from './components/AllCredentialHistory';
import PracticalTest from './components/instructor/PracticalTest';
import WpPage from './components/WpPage';
import AnalyticsProvider from './components/AnalyticsProvider';
import WelcomeModal from './components/modals/WelcomeModal';
import ExpirationModal from './components/modals/ExpirationModal';
import StudyAtHome from './components/StudyAtHome';
import StudyAtHomeModal from './components/modals/StudyAtHomeModal';

import { connect } from 'react-redux';
import {checkAuthentication} from './actions/authentication';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.checkAuthentication();
  }

  render() {
    if(this.props.isLoading) {
      return (
        <div></div>
      )
    }

    if(!this.props.isAuthenticated) {
      return (
        <div className="App">
          <Login/>
        </div>
      )
    }
    else {
      return (
        <div>
          <Router>
            <AnalyticsProvider>
              <div className="App">
                <Header/>
                <main id="main-content">
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/about-us" component={About} />
                    <Route exact path="/written/:writtenTestId" component={WrittenTest} />
                    <Route exact path="/darkness-to-light" component={DarknessToLight} />
                    <Route exact path="/pca" component={Pca} />
                    <Route exact path="/checkout" component={Checkout} />
                    <Route exact path="/confirmation" component={Confirmation} />
                    <Route exact path="/schedule-practical" component={SchedulePractical} />
                    <Route exact path="/credential-history" component={AllCredentialHistory}/>
                    <Route exact path="/practical/:testId" component={PracticalTest}/>
                    <Route exact path="/page/:pageId" component={WpPage}/>
                    <Route component={WpPage} />
                  </Switch>
                </main>
                <BottomArea/>
              </div>
              <ExpirationModal/>
            </AnalyticsProvider>
          </Router>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const {authentication} = state;

  return {
    isLoading: authentication.isLoading,
    isAuthenticated : authentication.isAuthenticated
  };
}
const mapDispatchToProps = {
  checkAuthentication: checkAuthentication,
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
