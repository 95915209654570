import React from 'react';
import {connect} from 'react-redux';
import {Button, Progress} from 'react-foundation';
import {Row, Column} from 'react-foundation';
import {withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  getNextStep, 
    getHighestLevelCompleted,
    getCoursePercentageCompletion,
    startNextReq,
    getNextReq,
    startCourse
} from "../../util/credentials";
import {addItemToCart} from '../../actions/commerce';

class NextStepButton extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.req) {
      this.state = {nextReq: this.props.req};
    }
    else {
      var nextReq = getNextReq(this.props.course);
      this.state = {nextReq: nextReq};
    }
  }

  getNextReqLevel = () => {
    var nextReq = getNextReq(this.props.course);
    var name = nextReq['name'];
    // return last character
    return name.substring(name.length - 1, name.length);
  }

  render () {
    if(typeof this.state.nextReq === 'undefined') {
      return (<span><br/></span>);
    }
    else if(this.state.nextReq.type == 'written') {
      var courseName = this.state.nextReq.name;
      var buttonText = '';

      if(typeof this.props.course != 'undefined' && this.props.course.name == "Cheer Building Level 7") {
        courseName = courseName.substring(27, courseName.length);
        buttonText = "Begin "+courseName;
      }
      else if(courseName == 'Intro to Understanding Rules &amp; Glossary') {
        courseName = "Introduction";
        buttonText = "Begin "+courseName+" Course";
      }
      else {
        if(courseName.indexOf("Level") !== -1) 
            courseName = courseName.substring(8, courseName.length-10);
        buttonText = "Begin "+courseName+" Course";
      }

      if(this.props.shortText) buttonText = "Begin Course";

      if(!(this.props.isLocked) && (this.state.nextReq.price == 0 || this.state.nextReq.purchased)) {
        return (
         <Button onClick={()=>{
          startCourse(this.state.nextReq, this.props.history);
        }} className="card-button">{buttonText}</Button>
        )
      }
      else if(this.state.nextReq.price == 0 || this.state.nextReq.purchased) {
        //if(this.props.shortText) return (<span>N/A</span>);
        return (
         <Button className="card-button" disabled={true}>{buttonText}</Button>
        )
      }
      else if(this.props.allowAddToCart && this.props.req) {
        var cartname = this.props.req.name;
        if(cartname == "Building Level 7 - Written Part 1"){
          cartname = "Cheer Building Level 7";
        }

        return (
          <Button className="card-button"
          onClick={(e)=>{
            this.props.addItemToCart(
              this.props.cart,
              {
                "id":    this.props.req.id,
                "name" : cartname,
                "price": this.props.price ? this.props.price : this.props.req.price,
                "type": "course"
              }
            );
          }}>Add to Cart</Button>
        )
      }
      else {
        if(this.props.naText) return (<span>{this.props.naText}</span>);
        if(this.props.shortText) return (<span>N/A</span>);
        return (
         <Button className="card-button" disabled={true}>{buttonText}</Button>
        )
      }
    }
    else if(this.state.nextReq.type == 'practical') {
      return (
        <Link to="/schedule-practical">
         <Button onClick={()=>{
        }} className="card-button">Schedule a Practical</Button>
        </Link>
        )
    }
    return (<span><br/></span>);
  }
}

function mapStateToProps(state) {
  const {account, commerce} = state;
  return {
    cart: commerce.cart,
    credentials: account.credentials
  };
}

const mapDispatchToProps = {
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NextStepButton));
