import React from 'react';
import { connect } from 'react-redux';

class ItemList extends React.Component {

  constructor(props) {
    super(props);

    var courses = [];
    var practicals = [];

    if(typeof this.props.items !== 'undefined') {
      for(var i=0; i < this.props.items.length; i++) {
        if(this.props.items[i].type === "practical-event")
          practicals.push(this.props.items[i]);
        else if(this.props.items[i].type === "practical-virtual")
          practicals.push(this.props.items[i]);
        else
          courses.push(this.props.items[i]);
      }
    }

    this.state = {
      courses,
      practicals
    }
  }

  render() {
    return(
      <div className="commerce-item-list">
      {this.state.courses.length > 0 && 
        <div className="course-list">
          <h3>Courses:</h3>
          { this.state.courses.map( (item) => 
              <div className="item-row" key={item.id}>
                 <dt>
                  <span>{item.name}</span>
                </dt>
                 <dd>
                  <span>${item.price.toFixed(2)}</span>
                </dd>
              </div>
          )}
        </div>

      }
      {this.state.practicals.length > 0 && 
        <div className="course-list">
          <h3>Practicals:</h3>
          { this.state.practicals.map( (item) => 
              <div className="item-row" key={item.id}>
                 <dt>
                  <span>{item.name}</span>
                </dt>
                 <dd>
                  <span>${item.price.toFixed(2)}</span>
                </dd>
                <div className="item-row-details">
                  <br/>
                  Details: {item.date}{" "}{item.time_pretty}{" "}
                  Testing for:{" "}{item.practical_names.join(', ')}
                </div>
              </div>
          )}
        </div>
      }
      </div>
    )
  }

}

export default ItemList;
