import React from 'react';
import { Route, Link } from 'react-router-dom'
import {Row, Column, Button} from 'react-foundation';
import logo from "../images/usasf-logo.jpg";
import { FaExternalLinkAlt } from 'react-icons/fa';


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      showForm:false
    }
  }

  render() {
    return (
      <main id="main-content" className="login-form">
        <div className="login-form-box">
          <Row>
            <Column small={12} style={{textAlign:'center'}}>
              <img className="logo" src={logo} alt="Logo" />
            </Column>
          </Row>
            <h1>Credentialing Portal</h1>
            
            {!this.state.showForm &&
              <div className="login-links">

                <a href="https://usasfmembers.net/#!/authentication/login/">
                  <Button className="card-button">
                    <FaExternalLinkAlt/>
                    {" "}
                    Coach Login
                  </Button>
                </a>
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.setState({showForm:true});
                }}>Instructor Login</a>
              </div>
            }

            { this.state.showForm &&
              <div>
                <a href="#" onClick={(e)=>{
                  e.preventDefault();
                  this.setState({showForm:false});
                }}>{"<"} Back</a>

                <form name="loginform" id="loginform" action="/wp/wp-login.php" method="post">
                  <input name="log" type="text" placeholder='Username'/>
                  <input name="pwd" type="password" placeholder='Password'/>
                  <input type="hidden" name="redirect_to" value="/"/>
                  <div style={{textAlign:'center'}}>
                    <input type="submit" name="wp-submit" id="wp-submit" className="button button-primary button-large" value="Log In"/>
                  </div>
                </form>
              </div>
            }
        </div>
      </main>
    );
  }
}

export default Login;
