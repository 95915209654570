import React from 'react';
import {Row, Column, Progress, Button} from 'react-foundation';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CreditCardInput from 'react-credit-card-input';
import { FaSpinner } from 'react-icons/fa';
import {placeOrder, closeCartModal} from '../actions/commerce';
import ItemList from './commerce/ItemList';

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    // Close modal (if open) on page load
    this.props.closeCartModal();

    this.state = {
      isCartConfirmed: false,

      cc_number: '',
      cc_exp: '',
      cc_cvc: '',
      ccIsFocused: false,

      validationErrors: [],

      orderError: null,

      address:'',
      city:'',
      state:'',
      zip: '',

      usStates: {
        "AL": "Alabama",
        "AK": "Alaska",
        "AS": "American Samoa",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FM": "Federated States Of Micronesia",
        "FL": "Florida",
        "GA": "Georgia",
        "GU": "Guam",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MH": "Marshall Islands",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "MP": "Northern Mariana Islands",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PW": "Palau",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VI": "Virgin Islands",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
      }
    }
  }

  handleInputChange = (e, key) => {
    this.setState({
     [key]: e.target.value
    })
  }

  removeValueFromArray = (value, array) => {
    for(var i=0;i<array.length;i++) {
      if(array[i] == value) {
        array.splice(i, 1);
        break;
      }
    }
    return array;
  }

  removeValuesFromArray = (values, array) => {
    // Make copy in case impermutable state value is passed
    var newArray = array;
    for(var i=0;i<values.length;i++) {
      newArray = this.removeValueFromArray(values[i], newArray);
    }
    return newArray;
  }

  handlePaymentInfoChange = (key, value) => {
    // Clear validation error
    var newErrors = this.removeValuesFromArray([key], this.state.validationErrors);
    this.setState({
      validationErrors: newErrors,
      [key]: value
    });
  }

  addValidationError = (key) => {
    if(this.state.validationErrors.includes(key) === false) {
      var newErrors = this.state.validationErrors;
      newErrors.push(key);
      this.setState({validationErrors: newErrors});
    }
  }

  placeOrder = (e) => {
    e.preventDefault();

    var newErrors = this.removeValuesFromArray(['address', 'city', 'state', 'zip'], this.state.validationErrors);

    // Validation
    if(this.state.address === "") {
      newErrors.push('address');
    }
    if(this.state.city === "") {
      newErrors.push('city');
    }
    if(this.state.state === "Please Select" || this.state.state === "") {
      newErrors.push('state');
    }
    if(this.state.zip === "") {
      newErrors.push('zip');
    }

    // Double check cc validation
    var ccFields = ['cc_number', 'cc_exp', 'cc_cvc'];
    for(var i=0;i<ccFields.length;i++){
      if(newErrors.includes(ccFields[i]) === false && this.state[ccFields[i]] === '') {
        newErrors.push(ccFields[i]);
      }
    }
    this.setState({validationErrors: newErrors, orderError: null});

    if(newErrors.length <= 0) {
      this.props.placeOrder(
        this.props.cart.items,
        this.state.address,
        this.state.city,
        this.state.state,
        this.state.zip,
        this.state.cc_number,
        this.state.cc_exp,
        this.state.cc_cvc
      );
    }
    else {
      console.log(newErrors);
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.lastOrder !== null) {
      this.props.history.push("/confirmation");
    }
    if(nextProps.orderError !== null) {
      this.setState({orderError: nextProps.orderError});
    }
  }

  render() {
    if(this.props.cart.items.length === 0) {
      return (
         <div>
          <Row>
            <div className="breadcrumb">
              <Link to="/">{"< "} Back to Dashboard</Link>
            </div>
          </Row>
          <Row>
            <Column small={12}>
              <h1>Checkout</h1>
              <div className="card checkout">
                <div className="text-center">
                  <h2>Shopping Cart Empty</h2>
                  <br/>
                  <Link to="/">
                    <Button>{"< "} Back to Dashboard</Button>
                  </Link>
                </div>
              </div>
            </Column>
          </Row>
        </div>
      )
    }


    if(this.state.isCartConfirmed) {
      return (
        <div>
          <Row>
            <div className="breadcrumb">
              <Link to="/">{"< "} Back to Dashboard</Link>
            </div>
          </Row>
          <Row>
            <Column small={12}>
            <h1>Checkout</h1>
              <div className="card checkout">

                {this.state.orderError !== null && this.state.orderError !== '' &&
                  <div className="error-message">
                    {this.state.orderError}
                  </div>
                }

                <h2>Billing Address</h2>
                <label>
                  <input className={this.state.validationErrors.includes('address') ? 'error' : ''} type="text" placeholder="Address" required value={this.state.address} onChange={(e)=>{this.handleInputChange(e, 'address');}}/>
                </label>
                  <div className="row">
                    <div className="small-12 medium-7 column">
                      <label>
                        <input className={this.state.validationErrors.includes('city') ? 'error' : ''} type="text" placeholder="City" required  value={this.state.city} onChange={(e)=>{this.handleInputChange(e, 'city');}}/>
                      </label>
                    </div>
                    <div className="small-6 medium-2 column">
                      <label>
                      <select className={this.state.validationErrors.includes('state') ? 'error' : ''} required value={this.state.state} onChange={(e)=>{this.handleInputChange(e, 'state');}} >
                        <option value="Please Select">Please Select</option>
                        { Object.keys(this.state.usStates).map((key, index) =>
                        <option key={key} value={key}>{this.state.usStates[key]}</option>
                        )}
                      </select>
                      </label>
                    </div>
                    <div className="small-6 medium-3 column">
                      <label>
                      <input className={this.state.validationErrors.includes('zip') ? 'error' : ''} type="text" placeholder="ZIP" required value={this.state.zip} onChange={(e)=>{this.handleInputChange(e, 'zip');}} />
                      </label>
                    </div>
                  </div>


                <h2>Payment</h2>
                <div className="cc-input">
                  <CreditCardInput
                    cardNumberInputProps={{ 
                      value: this.state.cc_number,
                      onChange: (e) => {this.handlePaymentInfoChange('cc_number', e.target.value);},
                      onError: err => {this.addValidationError('cc_number')}
                    }}
                    cardExpiryInputProps={{ 
                      value: this.state.cc_exp,
                      onChange: (e) => {this.handlePaymentInfoChange('cc_exp', e.target.value);},
                      onError: err => {this.addValidationError('cc_exp')}
                    }}
                    cardCVCInputProps={{ 
                      value: this.state.cc_cvc, 
                      onChange: (e) => {this.handlePaymentInfoChange('cc_cvc', e.target.value);},
                      onError: err => {this.addValidationError('cc_cvc')}
                    }}
                    fieldClassName="input"
                  />
                </div>

                <div className="checkout-footer">
                  <div className="pricing">
                    <span className="subtotal">
                      Total:<br/>
                      <span>${this.props.cart.total.toFixed(2)}</span>
                    </span>
                  </div>
                  { !this.props.orderProcessing &&
                    <Button onClick={(e)=>{this.placeOrder(e)}} className="button card-button buy" style={{marginRight:0}}>Purchase</Button>
                  }
                  { this.props.orderProcessing &&
                    <Button disabled className="button card-button buy" style={{marginRight:0}}><FaSpinner/></Button>
                  }
                </div>
              </div>
            </Column>
          </Row>
        </div>
      )
    }
    else {
      return(
        <div>
          <Row>
            <div className="breadcrumb">
              <Link to="/">{"< "} Back to Dashboard</Link>
            </div>
          </Row>
          <Row>
            <Column small={12}>
            <h1>Checkout</h1>
              <div className="card checkout">
                <ItemList items={this.props.cart.items}/>
                <div className="checkout-footer">
                  <div className="pricing">
                    <span className="subtotal">
                      Total:<br/>
                      <span>${this.props.cart.total.toFixed(2)}</span>
                    </span>
                  </div>
                  <Button onClick={(e)=>{this.setState({isCartConfirmed: true})}} className="button card-button buy" style={{marginRight:0}}>Buy Now</Button>
                </div>
              </div>
            </Column>
          </Row>

        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const {commerce, account} = state;
  return {
    account: account,
    modalVisible: commerce.modalVisible,
    cart: commerce.cart,
    orderProcessing: commerce.orderProcessing,
    lastOrder: commerce.lastOrder,
    orderError: commerce.error
  };
}

const mapDispatchToProps = {
  placeOrder: placeOrder,
  closeCartModal: closeCartModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
