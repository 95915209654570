import {AUTH_SUCCESS, AUTH_FAILURE} from '../actions/authentication';

export const initialState = {
  isLoading: true,
  isAuthenticated: false
};

export default function authentication(state = initialState, action) {
    switch (action.type) {
      case AUTH_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          isAuthenticated: true
        };
      }
      case AUTH_FAILURE: {
        return {
          ...state,
          isLoading: false,
          isAuthenticated: false
        };
      }
      default:
        return state;
    }
}
