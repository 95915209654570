import React from 'react';
import { Link } from 'react-router-dom';
import {TopBar, TopBarLeft, TopBarRight, Row, Column, Button} from 'react-foundation';
import { connect } from 'react-redux';
import logo from "../../images/usasf-logo.jpg";
import icon_bell from "../../images/icons/Bell.svg";
import icon_cart from "../../images/icons/Cart.svg";
import icon_chevron from "../../images/icons/Chevron.svg";
import default_profile from "../../images/default_profile.jpg";
import settings from "../../images/settings.png";
import indicator from "../../images/nav-arrow2.jpg";

import {removeItemFromCart} from "../../actions/commerce";

var getShortName = (name) =>{
    if(name.length > 30) {
        return name.substring(0,30)+"...";
    }
    return name;
  }

const DesktopHeader = props => (
          <header id="top-header">
            <TopBar className="outerpadding">
                <TopBarLeft>
                  <Link to="/">
                    <img className="logo" src={logo} alt="Logo" />
                  </Link>
                </TopBarLeft>
                <TopBarRight>
                  <div className="notifications nav-dropdown-top">
                    <img src={icon_bell} className="nav-icon" alt="Bell Icon" />
                    <div className="nav-dropdown-wrap">
                      <div className="nav-indicator" />
                      <div className="nav-dropdown">
                        <Row>
                          <Column small={12} className="notification-title-container">
                            <h1>Notifications</h1>
                            {/*<img src={settings} className="settings" alt="settings"/>*/}
                          </Column>
                          <Column small={12} className="notification-title-container">
                            <div className="notification-text">You have no notifications at this time.</div>
                          </Column>
                          {/*
                          <Column small={12} className="notification-title-container">
                            <div className="red-dot"></div>
                            <div className="notification-text">Cheer Building Credentials Levels 1-3 expire in 27 days!</div>
                          </Column>
                          <Column small={12} className="notification-title-container">
                            <div className="notification-text-size">Make sure your contact information is up to date.</div>
                          </Column>
                          <Column small={12} className="notification-title-container">
                            <div className="notification-text-size">View your cheer Building Level 1 Practical Scores.</div>
                          </Column>
                          <Column small={12}>
                          </Column>
                          */}
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="shopping-cart nav-dropdown-top">
                    <img src={icon_cart} className="nav-icon" alt="Cart Icon" />
                    { props.cart.items.length > 0 &&
                      <span className="cart-count">{props.cart.items.length}</span>
                    }
                    <div className="nav-dropdown-wrap">
                      <div className="nav-indicator" />
                      <div className="nav-dropdown">
                        <Row>
                          <Column small={12} className="notification-title-container">
                            <h1>Cart</h1>
                          </Column>
                          { props.cart.items.length == 0 &&
                          <Column small={12} className="notification-title-container">
                            <div className="notification-text">There are no items in the cart.</div>
                          </Column>
                          }
                          { props.cart.items.length > 0 &&
                            <div style={{width:'100%'}}>
                              { props.cart.items.map( (item) =>
                                <Column small={12} className="notification-title-container" key={item.id} style={{border:'none'}}>
                                  <div className="notification-text" style={{width:'100%'}}>
                                    {getShortName(item.name)}
                                    <span style={{float:'right'}}>${item.price.toFixed(2)}</span>
                                  </div>
                                </Column>
                              )}
                              <Row style={{clear:'both'}} className="cart-footer">
                                <Column small={6}>
                                  <span className="subtotal">
                                    SUBTOTAL<br/>
                                    <span>${props.cart.total.toFixed(2)}</span>
                                  </span>
                                </Column>
                                <Column small={6} className="text-right">
                                  <Link to="/checkout">
                                    <Button className="button card-button" style={{marginRight:0}}>Checkout</Button>
                                  </Link>
                                </Column>

                              </Row>
                            </div>
                          }
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="profile nav-dropdown-top">
                    <div className="name-field">
                      <div className="name">
                        {props.account.name.first}
                        {" "}
                        {props.account.name.last}
                      <div className="chevron-wrap">
                        <img className="avatar" src={icon_chevron} alt="Chevron" />
                      </div>
                      </div>
                    </div>
                    <img src={props.account.avatar} alt="Profile" className="avatar-image"/>

                    <div className="nav-dropdown-wrap">
                      <div className="nav-indicator" />
                      <div className="nav-dropdown">
                        <Row className="header">
                          <Column small={4} className="avatar">
                            <img src={props.account.avatar} alt="Profile" className="avatar-image"/>
                          </Column>
                          <Column small={8} className="bio">
                            <h2>{props.account.name.first + " " + props.account.name.last}</h2>
                          </Column>
                        </Row>
                        <br/>
                        <Row>
                          <Column small={12} className="notification-title-container">
                            <h1>Your Information:</h1>
                            {/*<img src={settings} className="settings" alt="settings"/>*/}
                          </Column>
                          <Column small={12} className="expanded-bio">
                            <p>
                              <strong>Email:</strong> {props.account.email}<br/>
                              <strong>USASF ID #:</strong> {
                                props.account.member_id !=''? props.account.member_id : "N/A"
                              }<br/>
                              {/*
                              <strong>Personal Cell:</strong> 216-123-4567<br/>
                              <strong>Personal Address:</strong> 1234 Cle Road, Cleveland, OH<br/>
                              */}
                            </p>
                            {/*
                            <p>
                              <strong>Gym:</strong> CLE Cheer + Dance Co.<br/>
                              <strong>Gym Address:</strong> 5678 Ohio Road, Cleveland, OH <br/>
                              <strong>Gym Phone:</strong> 216-987-6543<br/>
                            </p>
                            */}

                          </Column>
                          <Column small={12} className="links" style={{paddingBottom:0, marginBottom:-15}}>
                            <Link to="/credential-history">View complete credentialing history</Link><br/>
                            <Link to="/terms-of-use">View Terms of Use</Link><br/>
                            <Link to="/privacy-policy/">View Privacy Policy</Link>
                            {/*
                            <a href="#">View Official Rules</a><br/>
                            <a href="#">FAQ & Help Desk</a><br/><br/>
                            */}
                            <br/>
                            <a href="#logout" className="button secondary logout" onClick={props.logout}>Logout</a>
                          </Column>
                        </Row>

                      </div>
                    </div>

                  </div>

                </TopBarRight>
            </TopBar>
          </header>
)

function mapStateToProps(state) {
  const {commerce} = state;
  return {
    cart: commerce.cart
  };
}

function mapDispatchToProps(dispatch) {
  return({
    removeItemFromCart: removeItemFromCart
  })
}
export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader);
