import React from 'react'
import {connect} from 'react-redux';
import {Row, Column} from 'react-foundation';
import { FiAlertCircle, FiX } from "react-icons/fi";

class ExpirationWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closed: false
    }
  }

  shouldShow = () => {
    if(this.state.closed) return false;
    if(this.props.isFetching) return false;

    return this.getExpiringCredentials().length > 0;
  }

  parseYearFromDate = (date) => {
    var parts = date.split("/");
    return parts[parts.length-1];
  }

  getCurrentYear = () => {
    var date = new Date();
    return date.getFullYear();
  }

  getExpiringCredentials = () => {
    var expiringCredentials = new Array();
    if(this.props.isFetching) return expiringCredentials;

    var now = new Date();
    var cutOff = new Date(this.getCurrentYear()+'-05-01T00:00:00');
    var expDate = new Date(this.getCurrentYear()+'-05-31T23:59:59');

    for (var property in this.props.credentials) {
      if (this.props.credentials.hasOwnProperty(property)) {
        if(this.props.credentials[property].is_credentialed) {
          var expYear = this.parseYearFromDate(this.props.credentials[property].expiration);
          // expYear = this.getCurrentYear();
          if(expYear == this.getCurrentYear() && now >= cutOff) {
            var name = this.props.credentials[property].name;
            var days = Math.round((expDate-now)/(24*60*60*1000));
            if(days >= 0) {
              if(this.props.credentials.building.max_level  == 1) name += " Level 1";
              else name += " Levels 1-"+this.props.credentials[property].max_level;
              expiringCredentials.push({
                name: name,
                expiresIn: days
              });
            }
          }
        }
      }
    }

    return expiringCredentials;
  }

  render() {
    return (
      <div id="expiration-notice" style={{maxHeight: (!this.shouldShow()) ? 0 : 1000}}>
        <Row>
          <Column small={12}>
            {this.getExpiringCredentials().filter( (credential, index)=>{return index==0}).map((credential)=>
              <div className="expiration-text">
                <FiAlertCircle/>
                Your {credential.name} credentials EXPIRE in {credential.expiresIn} days! Please renew or add a level to keep your credentials active.
                <a href="#" className="close" onClick={(e)=>{
                  e.preventDefault();
                  this.setState({closed:true});
                }}>
                  <FiX/>
                </a>
              </div>
            )}
          </Column>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    isFetching: account.isFetching,
    credentials: account.credentials,
  };
}

export default connect(mapStateToProps)(ExpirationWarning);
