import { combineReducers } from 'redux'
import account from './account';
import authentication from './authentication';
import writtenTest from './writtenTest';
import commerce from './commerce';
import practicalTest from './practicalTest';

export default combineReducers({
  account,
  authentication,
  writtenTest,
  commerce,
  practicalTest
})
