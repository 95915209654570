import React from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {fetchWrittenTest, gradeWrittenTest, completeD2l} from '../actions/writtenTest';
import {Row, Column, Progress, Button} from 'react-foundation';

import ScormPlayer from './ScormPlayer';

class DarknessToLight extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
      sections: [
        // {
        //   "id": 1,
        //   "name": 'Bystanders Module',
        //   "description": 'Descrption text',
        //   "completed": false,
        //   "grade": 0
        // },
        // {
        //   "id": 2,
        //   "name": 'CSEC Module',
        //   "description": 'Descrption text',
        //   "completed": false,
        //   "grade": 0
        // },
        // {
        //   "id": 3,
        //   "name": 'Healthy Touch Module',
        //   "description": 'Descrption text',
        //   "completed": false,
        //   "grade":0
        // },
        // {
        //   "id": 4,
        //   "name": 'Recognizing and Reporting Module',
        //   "description": 'Descrption text',
        //   "completed": false,
        //   "grade":0
        //
        // },
        {
          "id": 1,
          "name": 'Stewards of Children Module',
          "description": 'Descrption text',
          "completed": false,
          "grade":0
        },
        // {
        //   "id": 6,
        //   "name": 'Talking with Children Module',
        //   "description": 'Descrption text',
        //   "completed": false,
        //   "grade":0
        // },
      ]
    }
  }

  canContinue = () => {
    if(this.state.sections[this.state.index-1]['completed']) {
      return true;
    }
    return false;
  }

  getProgress = () => {
    var completed = 0;
    for(var i=0;i<this.state.sections.length;i++) {
      if(this.state.sections[i]['completed'])
        completed++;
    }
    if(completed>0)
      return Math.round(completed/this.state.sections.length * 100);

    return 0;
  }

  startCourse = () => {
    this.setState({index:1});
  }

  handleOnCompletion = (score) => {
    if(score>=70) {
      this.state.sections[this.state.index-1]['completed'] = true;
      this.state.sections[this.state.index-1]['grade'] = score;
      this.setState({index: this.state.index++});
      if(this.state.index >= this.state.sections.length) {
        this.props.completeD2l();
      }
    }
  }
  goBack = () => {
    this.setState({index: this.state.index-1});
  }
  next = () => {
    var nextIndex = this.state.index +1;
    this.setState({index: nextIndex});
  }

  render() {
    return(
      <Row style={{minWidth:300}}>
        <Column small={12}>

          <div className="breadcrumb">
            <Link to="/">{"< "}&nbsp; Back to Dashboard</Link>
          </div>

          <div className="card written-test" style={{minWidth:300}}>

             <Row className="card-row hide-for-small-only">
                <Column medium={9} small={12}>
                  <h1 className="lesson-title">Darkness to Light</h1>
                </Column>

                <Column small={3} className="hide-for-small-only">
                  <Row>
                    <Column small={12} className="text-right">
                      <div className="card-title percentage" >
                        <span>
                          {this.getProgress()}% Complete
                          <Progress min={0} max={100} value={this.getProgress()} className="progress-bar" />
                          <Row>
                            <Column small={4} className="percentage-laebel text-left">0</Column>
                            <Column small={4} className="percentage-laebel text-center">50</Column>
                            <Column small={4} className="percentage-laebel text-right">100</Column>
                          </Row>
                        </span>
                      </div>
                    </Column>
                  </Row>
                </Column>
              </Row>

              { this.state.index == 0 &&
                <div className="lesson-content">
                  <h2>Course + Written Test</h2>
                  <div className="lesson-overview">
                    <Row>
                      <Column small={12} medium={6}>
                        <h3>Breakdown:</h3>
                        <ul className="breakdown">
                          { this.state.sections.map( (section) =>
                          <li key={section.id}>
                            {section.name}
                          </li>
                          )}
                        </ul>
                      </Column>
                    </Row>
                  </div>
                  <Button className="card-button" onClick={this.startCourse}>Start Course</Button>
                </div>
              }

              { this.state.index > 0  && this.state.index <= this.state.sections.length &&
                <div style={{minWidth:300}}>
                  <ScormPlayer url={"/lms/d2l/"+this.state.sections[this.state.index-1].id+"/player.html"} handleOnCompletion={this.handleOnCompletion} autoComplete={false}/>

                  <Row>
                    <Column small={6}>
                      <Button className="card-button" onClick={this.goBack}> Go Back</Button>
                    </Column>
                    <Column small={6} className="text-right">
                      { this.canContinue() &&
                        <Button className="card-button" onClick={this.next}>Next </Button>
                      }
                      { !this.canContinue() &&
                        <Button className="card-button" disabled onClick={this.next}>Next</Button>
                      }
                    </Column>
                  </Row>
                </div>
              }
              { this.state.index > this.state.sections.length &&
                <div className="lesson-content">
                  <Row>
                    <Column small={12} className="text-center">
                      <h2 style={{marginBottom:0}}>
                        <span className="grade">
                            <span className="success">
                              All Modules Complete
                            </span>
                        </span>
                      </h2>
                        <Row className="next-steps">
                          <Column small={12} className="text-center">
                              <div className="return-to-dash">
                                <p className="retake">Congratulations! You have successfully passed this course.</p>
                                <Button className="card-button" onClick={(e)=>{ this.props.history.push('/');}}>Return to Dasboard</Button>
                              </div>
                          </Column>
                        </Row>
                    </Column>
                  </Row>
                </div>
              }

                </div>
          </Column>
        </Row>
    )
  }


}

function mapStateToProps(state) {
  const {account} = state;
  return {
    account: account
  };
}
const mapDispatchToProps = {
  completeD2l: completeD2l,
};

export default connect(mapStateToProps, mapDispatchToProps)(DarknessToLight);
