import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import {addItemsToCart, addItemToCart} from '../../actions/commerce';
import {startCourse} from '../../util/credentials';
import {withRouter} from 'react-router-dom';

class ExpirationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };
  }

  shouldShowModal = () =>{
    if(this.props.account.isFetching)
      return false;

    if(this.props.credentials.isFetching)
      return false;

    if(!this.state.isOpen)
      return false;

    return this.getAllExpiredCredentials().length > 0;
  }

  parseYearFromDate = (date) => {
    var parts = date.split("/");
    return parts[parts.length-1];
  }

  getAllExpiredCredentials = () => {
    var expiredCredentials = new Array();

    var now = new Date();
    var cutOff = new Date(this.getCurrentYear()+'-05-31T23:59:59');

    if(this.props.credentials.building.is_credentialed) {
      var expYear = this.parseYearFromDate(this.props.credentials.building.expiration);
      // expYear = this.getCurrentYear();
      if(expYear == this.getCurrentYear() && now >= cutOff) {
        expiredCredentials .push(this.props.credentials.building);
      }
    }
    if(this.props.credentials.tumbling.is_credentialed) {
      var expYear = this.parseYearFromDate(this.props.credentials.tumbling.expiration);
      if(expYear == this.getCurrentYear() && now >= cutOff) {
        expiredCredentials.push(this.props.credentials.tumbling);
      }
    }
    return expiredCredentials;
  }

  closeModal = () => {
    this.setState({isOpen: false});
  }

  getCurrentYear = () => {
    var date = new Date();
    return date.getFullYear();
  }

  renew = (credential) => {
    var items = [];
    for(var j=0;j<credential.max_level;j++) {
      var course = credential.credentials[j];
      for(var i=0;i<course.reqs.length;i++) {
        if(course.reqs[i]['type'] === 'written' && !course.reqs[i]['purchased']) {
         items.push({
            "id":    course.reqs[i].id,
            "name" : course.reqs[i].name,
            "price": course.reqs[i].price,
            "type": "course"
          });
        }
      }
    }
    this.props.addItemsToCart(
      this.props.cart, items
    );
    this.setState({isOpen:false});
  }
  
  addLevel = (req) => {
     this.props.addItemToCart(
      this.props.cart, {
        "id":    req.id,
        "name" : req.name,
        "price": req.price,
        "type": "course"
      }
    );
    this.setState({isOpen:false}); 
  }

  handleStartCourse = (req) => {
    this.setState({isOpen:false}, ()=>{
      startCourse(req, this.props.history);
    });
  }

  render() {
    if(this.shouldShowModal()) {
      return(
        <div>
          <div className="modal-overlay" onClick={this.closeModal}></div>
          <div className="modal-content" id="expiration-modal">
            <a href="#close" className="close-modal" onClick={this.closeModal}><FaTimes/></a>
            <div className="inner-content">
              <h1>Your Credentials have EXPIRED!</h1>
              <p>
                Please renew before December 31st, {this.getCurrentYear()} to save your credentialing history. 
                If you let your credentials expire past the six-month grace period, all of 
                your credentialing history will be voided and you will have to start over 
                as a new credentialing candidate.
              </p>
              <div className="expired-credentials">
                {this.getAllExpiredCredentials().map( (credential, index) =>
                  <div key={index} style={{marginBottom:15}}>
                    <strong>
                      {credential.name} Level {credential.max_level}
                      <div className="buttons">
                        <Button onClick={(e)=>{e.preventDefault(); this.renew(credential)}}>Renew</Button>
                        {credential.credentials[credential.max_level] && credential.credentials[credential.max_level].reqs && credential.max_level <= credential.credentials.length &&
                          <>
                            <span>OR</span>
                            {credential.credentials[credential.max_level].reqs[0].purchased &&
                              <Button onClick={(e)=>{e.preventDefault();this.handleStartCourse(credential.credentials[credential.max_level].reqs[0])}}>Start Level {credential.max_level+1}</Button>
                            }
                            {!credential.credentials[credential.max_level].reqs[0].purchased &&
                              <Button onClick={(e)=>{e.preventDefault();this.addLevel(credential.credentials[credential.max_level].reqs[0])}}>
                                Add Level {credential.max_level+1}
                              </Button>
                            }
                          </>
                        }
                      </div>
                    </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return(
      <div></div>
    );
  }
}
function mapStateToProps(state) {
  const { account, commerce } = state;
  return {
    credentials: account.credentials,
    account: account,
    cart: commerce.cart
  };
}

const mapDispatchToProps = {
  addItemsToCart: addItemsToCart,
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpirationModal));
