import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import { connect } from 'react-redux';
import tick from "../../images/tick.png";
import {addItemToCart} from "../../actions/commerce";
import {formatTimestamp} from "../../util/credentials";

class PrerequisiteItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    if(this.props.item.is_complete) {
      return (
        <div className="prerequisite-item">
           <Row className="label-content-gap">
              <Column small={12} className="text-left">
                 <img src={tick} className="tick" alt="tick"/>
                 <div className="item-title">{this.props.item.name}</div>
                 { this.props.item.completed_on == null &&
                    <div className="list-date">Completed</div>
                 }
                 { this.props.item.completed_on != null &&
                    <div className="list-date">Completed {formatTimestamp(this.props.item.completed_on)}</div>
                 }
              </Column>
            </Row>
        </div>
      )
    }
    else if(this.props.item.is_optional) {
      return (
        <div className="prerequisite-item prerequisite-item-optional">
           <Row className="label-content-gap">
              <Column small={9} className="text-left">
                 <div className="item-title">{this.props.item.name} (Optional)</div>
                

              </Column>
              <Column small={3} className="text-right">
                <Button 
                style={{backgroundColor:'#EAEAEA', color:'#000'}}
                onClick={(e)=>{
                  this.props.startCourse(this.props.item);
                
                }}>Start Course</Button>
              </Column>
            </Row>
        </div>
      )
    }
    else if(this.props.item.purchased || this.props.item.price == 0) {
      return (
        <div className="prerequisite-item prerequisite-item-incomplete">
           <Row className="label-content-gap">
              <Column small={9} className="text-left">
                 <div className="item-title">{this.props.item.name}</div>
              </Column>
              <Column small={3} className="text-right">
                <Button 
                style={{backgroundColor:'#EAEAEA', color:'#000'}}
                onClick={(e)=>{
                  this.props.startCourse(this.props.item);
                
                }}>Start Course</Button>
              </Column>
            </Row>
        </div>
      )
    }
    else {
      return (
        <div className="prerequisite-item prerequisite-item-incomplete">
           <Row className="label-content-gap">
              <Column small={9} className="text-left">
                 <div className="item-title">{this.props.item.name}</div>
              </Column>
              <Column small={3} className="text-right">
                <Button 
                style={{backgroundColor:'#EAEAEA', color:'#000'}}
                onClick={(e)=>{
                  this.props.addItemToCart(
                    this.props.cart,
                    {
                      "id": this.props.item.id,
                      "name" : this.props.item.name,
                      "price": this.props.item.price,
                      "type": "course"
                    }
                  );
                }}>Add to Cart</Button>
              </Column>
            </Row>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  const {commerce} = state;
  return {
    cart: commerce.cart
  };
}

const mapDispatchToProps = {
  addItemToCart: addItemToCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrerequisiteItem);
