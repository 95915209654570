import {
  ACCOUNT_FETCH_START,
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_FETCH_FAILURE,
  CREDENTIALS_FETCH_START,
  CREDENTIALS_FETCH_SUCCESS,
  CREDENTIALS_FETCH_FAILURE,
} from "../actions/account";

export const initialState = {
  isFetching: false,
  id: '',
  member_id: '',
  email: '',
  name: {
    first: '',
    last: ''
  },
  prereqs: [],
  credentials: [],
  scheduledPracticals: []
};


export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_FETCH_START: {
      return {
        ...state,
        isFetching: true
      }
    }
    case ACCOUNT_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.data
      }
    }
    case CREDENTIALS_FETCH_SUCCESS: {
      return {
        ...state,
        credentials: action.data,
        isFetching: false
      }
    }
    default:
      return state;
  }
}

