import React from 'react';
import { Link } from 'react-router-dom';
import {TopBar, TopBarLeft, TopBarRight, Row, Column} from 'react-foundation';
import logo from "../../images/usasf-logo.jpg";
import { slide as SlideMenu } from 'react-burger-menu';
import { FaBars } from 'react-icons/fa';

class MobileHeader  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  render() {
    return (
      <div className="mobile-menu">
          <SlideMenu isOpen={this.state.isOpen} right 
            disableOverlayClick={()=>{
              this.setState({isOpen:false});
              return true;
            }}
            customOnKeyDown={()=>{}} // do nothing
          >
            <div className="click-cache" onClick={(e)=>{
                this.setState({isOpen:false});
              }}
              style={{
                width:'100%'
              }}
            >
              <Link to="/">Dashboard</Link>
              <Link to="/checkout">Shopping Cart</Link>
              <Link to="/credential-history">Profile</Link>
              <a href="#"  onClick={this.props.logout}>Logout</a>
            </div>
          </SlideMenu>

        <header id="top-header" className="columns small-12">

          <TopBar className="outerpadding">
                  <img className="logo" src={logo} alt="Logo" />
                  <a href="#" id="hamburger-menu" onClick={()=>{
                    this.setState({isOpen:true});
                  }}><FaBars size="3em"/></a>
          </TopBar>
        </header>
      </div>
    )
  }
}

export default MobileHeader;
