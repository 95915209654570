import {
  PRACTICAL_SCHEDULE_FETCH_START,
  PRACTICAL_SCHEDULE_FETCH_SUCCESS,
  PRACTICAL_SCHEDULE_FETCH_FAILURE,
  
  SCHEDULED_PRACTICALS_FETCH_START,
  SCHEDULED_PRACTICALS_FETCH_SUCCESS,
  SCHEDULED_PRACTICALS_FETCH_FAILURE,
  
  PRACTICAL_TEST_FETCH_START,
  PRACTICAL_TEST_FETCH_SUCCESS,

  PRACTICAL_TEST_GRADE_START,
  PRACTICAL_TEST_GRADE_SUCCESS,
  PRACTICAL_TEST_GRADE_FAILURE,

  SCHEDULE_PRACTICAL_START,
  SCHEDULE_PRACTICAL_SUCCESS,
  SCHEDULE_PRACTICAL_FAILURE,

  PRACTICAL_TEST_RESET
} from "../actions/practicalTest";

export const initialState = {
  isFetching: true,
  isGrading: false,
  isScheduling: false,
  grade: null,
  schedules: {
    virtual: [],
    events: []
  },
  scheduledPracticals: [],
  practicalTest: {},
  hasSchedulingError: false
};


export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case PRACTICAL_TEST_RESET: {
      return {
        ...state,
        isFetching: false,
        isGrading: false,
        error: null
      }
    }
    case PRACTICAL_SCHEDULE_FETCH_START: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case PRACTICAL_SCHEDULE_FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        schedules: action.data
      }
    }
    case PRACTICAL_SCHEDULE_FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }

    case SCHEDULED_PRACTICALS_FETCH_START: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case SCHEDULED_PRACTICALS_FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        scheduledPracticals: action.data
      }
    }

    case PRACTICAL_TEST_FETCH_START: {
      return {
        ...state,
        isFetching: true,
        grade: null,
        error: null
      }
    }
    case PRACTICAL_TEST_FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        practicalTest: action.data
      }
    }
    case PRACTICAL_TEST_GRADE_START: {
      return {
        ...state,
        isGrading: true,
      }
    }
    case PRACTICAL_TEST_GRADE_SUCCESS: {
      console.log("Grade", action.data);
      return {
        ...state,
        isGrading: false,
        grade: action.data,
      }
    }
    case PRACTICAL_TEST_GRADE_FAILURE: {
      return {
        ...state,
        isGrading: false,
        error: action.error
      }
    }
    case SCHEDULE_PRACTICAL_START: {
      return {
        ...state,
        isScheduling: true,
        hasSchedulingError: false
      }
    }
    case SCHEDULE_PRACTICAL_SUCCESS: {
      return {
        ...state,
        isScheduling: false,
      }
    }
    case SCHEDULE_PRACTICAL_FAILURE: {
      return {
        ...state,
        isScheduling: true,
        hasSchedulingError: true
      }
    }
    default:
      return state;
  }
}

