import React from 'react';
import {Row, Column, Progress, Button} from 'react-foundation';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AddSuccess from '../images/icons/add-success.png';
import {triggerConfirmationView} from '../actions/commerce';
import ItemList from './commerce/ItemList';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.lastOrder == null)
      this.props.history.push("/");

    this.state = {lastOrder: this.props.lastOrder};
    this.props.triggerConfirmationView();
  }

  printPage = (e) => {
    e.preventDefault();
    window.print();
  }

  render() {
    if(this.state.lastOrder === null) {
      return(<div></div>);
    }
    return(
        <div>
          <Row>
            <div className="breadcrumb">
              <Link to="/">{"< "} Back to Dashboard</Link>
            </div>
          </Row>
          <Row>
            <Column small={12}>
              <div className="card checkout confirmation">
                <div className="text-center">
                  <img src={AddSuccess}/>
                  <h1>Payment Confirmed</h1>
                  <h2>
                    <span className="print-only">
                      { this.state.lastOrder.date } <br/>
                      { this.props.account.name.first + " " + this.props.account.name.last + " (#" + this.props.account.member_id + ")" }
                    </span>
                    {this.state.lastOrder.total} Billed to {this.state.lastOrder.last4}
                  </h2>
                </div>
            
                <ItemList items={this.state.lastOrder.items}/>

                <div className="confirmation-footer">
                  <div className="button-group">
                    <Button onClick={this.printPage}>Print This Page</Button>
                    <br/>
                    <Link to="/">
                      <Button>Back to Dashboard</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </div>
    )
  }
}

function mapStateToProps(state) {
  const {commerce, account} = state;
  return {
    account: account,
    modalVisible: commerce.modalVisible,
    cart: commerce.cart,
    orderProcessing: commerce.orderProcessing,
    lastOrder: commerce.lastOrder
  };
}

const mapDispatchToProps = {
  triggerConfirmationView: triggerConfirmationView
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
