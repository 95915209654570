import React from 'react';
import Loading from './Loading';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {fetchWrittenTest, gradeWrittenTest, completeD2l} from '../actions/writtenTest';
import {Row, Column, Progress, Button} from 'react-foundation';
import $ from "jquery";

class Pca extends React.Component {
  constructor(props) {
    super(props)

    this.state = {index:0, isLoading:true};
    this.getSSOForm();
  }

  startCourse = () => {
    this.setState({index:1});
  }

  goBack = () => {
    this.setState({index: this.state.index-1});
  }

  next = () => {
    this.setState({index: this.state.index++});
  }

  getProgress = () => {
    return 0;
  }

  getSSOForm = () => {
     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/pca-sso'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {
      this.setState({
        isLoading:false,
        form: response
      });

    } ).fail((response)=> {
        this.setState({
            form: "<h1 style='color:red'>An error occured while connecting to PCA</h1>"
        });
    });
  }

  render() {
    return(
      <Row style={{minWidth:300}}>
        <Column small={12}>

          <div className="breadcrumb">
            <Link to="/">{"< "}&nbsp; Back to Dashboard</Link>
          </div>

          <div className="card written-test" style={{minWidth:300, padding:0}}>
              { this.state.index == 0 && this.state.isLoading == false &&
                  <div dangerouslySetInnerHTML={{__html:this.state.form}} />
              }
          </div>
          </Column>
        </Row>
    )
  }


}

function mapStateToProps(state) {
  const {account} = state;
  return {
    account: account
  };
}
const mapDispatchToProps = {
  completeD2l: completeD2l,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pca);
