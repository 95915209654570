import React from 'react';
import { connect } from 'react-redux'
import Loading from './Loading.js';
import $ from "jquery";
import {Row, Column, Progress, Button} from 'react-foundation';
import {Link} from "react-router-dom";
import tick from "../images/tick.png";
import tickAlt from "../images/tick-gray.png";

class StudyAtHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      generalCourses: [],
      buildingCourses: [],
      tumblingCourses: [],
      fetchingGrades: true,
      covidGrades: []
    };
    this.getCovidGrades();
  }

  componentDidMount() {
    this.parseCourses();
  }

  getCovidGrades = ()=>{
    $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/study-at-home-grades/'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    }).done( ( response ) => {
      this.setState({
        covidGrades: response.grades,
        fetchingGrades: false
      });
    } ).fail((e)=> {
      this.setState({
        fetchingGrades: false
      });
    });
  }

  // Courses Loaded
  componentDidUpdate(prevProps) {
    if(this.props.isFetching == false && this.state.generalCourses.length == 0) {
      this.parseCourses();
    }
  }

  isCourseInArray = (array, key) => {
    for(let i=0;i<array.length;i++) {
      if(array[i].id == key) return true;
    }
    return false;
  }

  parseCourses = ()=>{
    let generalCourses  = [];
    let buildingCourses = [];
    let tumblingCourses = [];
    if(typeof this.props.credentials.building != 'undefined'){
      for(let i=0;i<this.props.credentials.building.credentials.length;i++){
        if(i==0) {
          generalCourses = this.props.credentials.building.credentials[i].pre_reqs.filter(c=>c.type=='written');
        }
        let writtenTests = this.props.credentials.building.credentials[i].reqs.filter(r=>r.type=='written');
        for(let j=0;j<writtenTests.length;j++){
          if(!this.isCourseInArray(buildingCourses,writtenTests[j].id)){
            buildingCourses.push(writtenTests[j]);
          }
        }
      }
    }
    if(typeof this.props.credentials.tumbling != 'undefined'){
      for(let i=0;i<this.props.credentials.tumbling.credentials.length;i++){
        let writtenTests = this.props.credentials.tumbling.credentials[i].reqs.filter(r=>r.type=='written');
        for(let j=0;j<writtenTests.length;j++){
          if(!this.isCourseInArray(tumblingCourses,writtenTests[j].id)){
            tumblingCourses.push(writtenTests[j]);
          }
        }
      }
    }
    this.setState({
      generalCourses,
      buildingCourses,
      tumblingCourses,
    });
  }

  isComplete = (id) => {
    let allCourses = this.state.generalCourses.concat(this.state.buildingCourses).concat(this.state.tumblingCourses);
    for(let i=0;i<allCourses.length;i++) {
      if(allCourses[i].id == id && allCourses[i].completed) return true;
    }
    for(let i=0;i<this.state.covidGrades.length;i++) {
      if(this.state.covidGrades[i].id == id && this.state.covidGrades[i].passed) return true;
    }
    return false;
  }

  render() {
    const Header = () => (
      <Row className="wp-page">
        <Column small={12}>
          <h1>Study at Home</h1>
          <p>
            During this social distancing time, we have unlocked all of our written level tests so you can begin your credentialing process prior to paying.
          </p>
          <p>
            Please note: completing/passing the written level tests in the Study at Home section does not mean you are credentialed.  Once all components of the credentialing system are completed and paid for you are then considered credentialed.
          </p>
        </Column>
      </Row>
    )

    if(this.props.isFetching || this.state.fetchingGrades) {
      return(
        <div>
          <Header/>
          <Row>
            <Loading/>
          </Row>
        </div>
      )
    }
    return(
      <div>
        <Header/>
        <Row>
          <Column small={12} className="card">
            <h2>General Courses</h2>
            <br/>
            {this.state.generalCourses.map(c=>
              <StudyAtHomeCourse course={c} key={c.id} isComplete={this.isComplete(c.id)}/>
            )}
          </Column>
        <Row>
        </Row>
          <Column small={12} medium={6} className="card">
            <h2>Building Courses</h2>
            <br/>
            {this.state.buildingCourses.map(c=>
              <StudyAtHomeCourse course={c} key={c.id} isComplete={this.isComplete(c.id)}/>
            )}
          </Column>
          <Column small={12} medium={6} className="card">
            <h2>Tumbling Courses</h2>
            <br/>
            {this.state.tumblingCourses.map(c=>
              <StudyAtHomeCourse course={c} key={c.id} isComplete={this.isComplete(c.id)}/>
            )}
          </Column>

        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {account} = state;
  return {
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

export default connect(mapStateToProps)(StudyAtHome);

class StudyAtHomeCourse extends React.Component {
  render(){
    const {course, isComplete} = this.props;
    return (
      <div className="study-at-home-course">
         <Row>
            <Column small={12} className="text-left">
              { isComplete &&
                <img src={tick} className="tick" alt="Completed"/>
              }
              { !isComplete &&
                <img src={tickAlt} className="tick gray" alt="Not Completed"/>
              }
              <div className="item-title" dangerouslySetInnerHTML={{__html: course.name}}/>
              <Link to={"/study-at-home/"+course.id}>
                { isComplete &&
                  <Button>Retake Course</Button>
                }
                { !isComplete &&
                  <Button>Start Course</Button>
                }
              </Link>
            </Column>
          </Row>
      </div>
    );
  }
}


