import React, { Component } from 'react';
import {Row, Column, Button} from 'react-foundation';
import CollapsibleGroup from './CollapsibleGroup';
import { Link } from 'react-router-dom';

import { FaLock } from 'react-icons/fa';
import {connect} from 'react-redux';

import {getAvailablePracticals} from '../util/credentials';

class AvailablePracticals extends Component {
  isPracticalLocked = (req) => {
    var availablePracticals = getAvailablePracticals(this.props.credentials);

    for(var i=0;i<availablePracticals.length;i++) {
      if(availablePracticals[i].id == req.id)
        return false;
    }
    return true;
  }

  render() {
    if(getAvailablePracticals(this.props.credentials).length === 0){
        return(<div></div>);
    }
    return (
      <div className={getAvailablePracticals(this.props.credentials).length === 0 ? 'hide-for-small-only' : ''}>
        <CollapsibleGroup header="Practicals Available For Testing" isCollapsed={false}>
          <div>
            <Row className="practical">
              <Column medium={6} className="hide-for-small-only">
                <Row>
                    <Column small={12} className="text-left practical-cell">
                     { this.props.course.credentials.filter((credential, index)=>{
                       if(this.props.course.name == "Building Level 7")
                        return (index == 6);
                       else
                        return (index<=5);
                     }).map( (credential, index) =>
                      <div key={index}>
                        { credential.reqs.filter( (req) => {return req.type == 'practical'} ).map( (req) =>
                          <div className={this.isPracticalLocked(req) ? 'locked' : ''} key={index}>
                              <div className="practical-list-item" key={req.id}>
                                {req.name}

                                { this.isPracticalLocked(req) &&
                                  <span style={{paddingLeft:15, fontSize:'1em'}}>
                                    <FaLock/>
                                  </span>
                                }

                              </div>
                          </div>
                        )}
                      </div>
                      )}
                    </Column>
                </Row>
              </Column>
              <Column medium={6} small={12}>
                <Row>
                  <Column small={12} className="text-left">
                    {this.props.course.name != 'Cheer Building Level 7' &&
                    <p>
                      *Each practical session, no matter how many tests you're taking, costs $25. We recommend scheduling as many tests as you can per session.
                    </p>
                    }
                  </Column>
                  <Column small={12} className="text-right">
                    <Link to="/schedule-practical">
                      <Button className="cell-button pracitcal-button">Schedule a Practical Session</Button>
                    </Link>
                  </Column>
                </Row>
              </Column>
            </Row>
          </div>
        </CollapsibleGroup>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    credentials: account.credentials,
  };
}

export default connect(mapStateToProps)(AvailablePracticals);
