import React from 'react';
import {Row, Column, Button} from 'react-foundation';
import {connect} from 'react-redux';

import CollapsibleGroup from './CollapsibleGroup';
import tick from "../images/tick.png";

import { FaLock } from 'react-icons/fa';

import NextStepButton from './progressCard/NextStepButton';
import AvailablePracticals from './AvailablePracticals';

import {startCourse, formatMoney, formatTimestamp} from '../util/credentials';
import {addItemToCart} from '../actions/commerce';
import {withRouter} from 'react-router-dom';

class InProgressTable extends React.Component {
  constructor(props) {
    super(props);
  }

  isReqLocked = (index, renewing=false) => {
    var lastWrittenCompleted = 0;

    this.props.course.credentials.map((credential)=> {
      // console.log("credential", credential);
      if(typeof credential.reqs !== 'undefined') {
        credential.reqs.filter( (req)=>{ return req.type == 'written'} ).map( (req) => {
          if(req.completed) lastWrittenCompleted++;
          //else lastWrittenCompleted-0; //break map
        })
      }
    });
    return index >= (lastWrittenCompleted+1);
  }

  

  render() {
    return (
      <div>
        { !this.props.hidePreReqs &&
        <Row>
          {this.props.course.credentials[0].pre_reqs.map( (req, index) => 
            <Column small={12} className="text-left inprogress-item" key={index}>
              { req.completed &&
                <div>
                  <img src={tick} className="tick" alt="tick"/>
                  <div className="inprogress-item-title" dangerouslySetInnerHTML={{__html:req.name}}/>
                  <div className="complete-date">Completed {formatTimestamp(req.completed_date)}</div>
                </div>
              }
              { !req.completed &&
                <div>
                  <div className="inprogress-item-title" style={{marginLeft:0}} dangerouslySetInnerHTML={{__html:req.name}}/>
                  <div style={{float:'right'}}>
                    { (req.price == 0 || req.purchased) &&
                      <Button onClick={(e)=>{startCourse(req, this.props.history);}}>Start Couse</Button>
                    }
                    { req.price > 0 && !req.purchased &&

                       <Button className="card-button"
                        onClick={(e)=>{
                          this.props.addItemToCart(
                            this.props.cart,
                            {
                              "id":    req.id,
                              "name" : req.name,
                              "price": req.price,
                              "type": "course"
                            }
                          );
                        }}>Add to Cart</Button>
                    }
                  </div>
                </div>
              }
            </Column>
          )}
        </Row>
        }
        { this.props.hidePreReqs &&
        <Row>
          {this.props.course.credentials[0].pre_reqs.filter((req)=>{return !req.completed}).map( (req, index) => 
            <Column small={12} className="text-left inprogress-item" key={index}>
              <div>
                <div className="inprogress-item-title" style={{marginLeft:0}} dangerouslySetInnerHTML={{__html:req.name}}/>
                <div style={{float:'right'}}>
                  { (req.price == 0 || req.purchased) &&
                    <Button onClick={(e)=>{startCourse(req, this.props.history);}}>Start Couse</Button>
                  }
                  { req.price > 0 && !req.purchased &&

                     <Button className="card-button"
                      onClick={(e)=>{
                        this.props.addItemToCart(
                          this.props.cart,
                          {
                            "id":    req.id,
                            "name" : req.name,
                            "price": req.price,
                            "type": "course"
                          }
                        );
                      }}>Add to Cart</Button>
                  }
                </div>
              </div>
            </Column>
          )}
        </Row>
        }


        <CollapsibleGroup header={this.props.course.name} tag={this.props.course.in_progress ? 'In-Progress' : '' } isCollapsed={false}>
            {/* TABE HEADER */}
            <Row className="table-header-line">
              <Column small={4} style={{paddingLeft:0}}>
                <div className="inProgress-table-header">Item</div>
              </Column>
              {/*
              <Column small={2}>
                <div className="inProgress-table-header">Time to Complete</div>
              </Column>	
              */}
              <Column small={2}>
                <div className="inProgress-table-header">Status</div>
              </Column>	
              <Column small={2}>
                <div className="inProgress-table-header">Expires</div>
              </Column>	
              <Column small={2} className="text-center">
                <div className="inProgress-table-header">Cost</div>
              </Column>	
              <Column small={2} className="text-center">
                <div className="inProgress-table-header">Action</div>
              </Column>	
            </Row>
            {/* TABLE BODY */}
            { this.props.course.credentials.filter((credential, index)=>{return (index<=5);}).map( (credential, index) =>
              <div className={this.isReqLocked(index) ? 'locked progress-row' : 'progress-row'} key={index}>
                { credential.reqs.filter( (req) => {return req.type == 'written'} ).map( (req) => 
                  <Row style={{paddingTop:'2em'}} key={req.id}>
                    <Column small={4} style={{paddingLeft:0}}>
                      <div className="">
                        { req.completed &&
                          <img src={tick} className="tick" alt="tick"/>
                        }
                        { req.name.substring(8, req.name.length-10)} Course
                        {"  "}
                        { this.isReqLocked(index) &&
                          <span style={{paddingLeft:15, fontSize:'1em'}}>
                            <FaLock/>
                          </span>
                        }
                      </div>
                    </Column>
                    {/*
                    <Column small={2}>
                      <div className="inProgress-table-header">Time to Complete</div>
                    </Column>	
                    */}
                    <Column small={2}>
                      {req.completed &&
                        <div className="">Completed {formatTimestamp(req.completed_date)}</div>
                      }
                      {!req.completed &&
                        <div className="">Incomplete</div>
                      }
                    </Column>	
                    <Column small={2}>
                      <div className="">{ credential.expiration == null ? '-' : credential.expiration}</div>
                    </Column>	
                    <Column small={2} className="text-center">
                      <div className="">{ req.purchased ? 'PAID' : formatMoney(req.price) }</div>
                    </Column>	
                    <Column small={2} className="text-center">
                      <div className="">
                        <NextStepButton
                          isLocked={this.isReqLocked(index)}
                          req={req} 
                          shortText={true}
                          allowAddToCart={!req.completed}
                        />
                      </div>
                    </Column>	
                  </Row>
                )}
              </div>
            )}
        </CollapsibleGroup>
      </div>
    )
  }


}

function mapStateToProps(state) {
  const {account, commerce} = state;
  return {
    cart: commerce.cart,
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

const mapDispatchToProps = {
  addItemToCart: addItemToCart,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InProgressTable));
