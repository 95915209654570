import React from 'react';
import Loading from './Loading.js';
import $ from "jquery";
import {Row, Column, Progress, Button} from 'react-foundation';

class WpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      path: this.props.location ? this.props.location.pathname : ''
    };
    this.fetchPage();
  }

  // componentWillReceiveProps = (newProps) => {
  //   if(newProps.location.pathname != this.state.pathname) {
  //     this.setState({path: newProps.location.pathname});
  //     this.fetchPage();
  //   }
  // }
  //
  componentDidUpdate = (prevProps) => {
    // Only update page on url change if
    // page content is loaded based on the path
    if(!this.props.pageId && !this.props.slug) {
      if(prevProps.location.pathname != this.props.location.pathname) {
        this.setState({
          isLoading:true,
          path: this.props.location.pathname
        });
        this.fetchPage();
      }
    }
  }

  fetchPage = () => {
    if(this.props.slug) {
      this.fetchPageBySlug(this.props.slug);
    }
    else if(this.props.match.params.pageId) {
      this.fetchPageById(this.props.match.params.pageId);
    }
    else {
      this.fetchPageBySlug(this.props.location.pathname);
    }
  }

  fetchPageById = (pageId) => {
     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'wp/v2/pages/'
          + this.props.match.params.pageId
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {
      this.setState({
        isLoading:false,
        title: response.title.rendered,
        content: response.content.rendered
      });

    } ).fail((response)=> {
    });
  }

  fetchPageBySlug = (slug) => {
      //sitename.com/wp-json/wp/v2/pages?slug=page-slug
     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'wp/v2/pages/?slug='
          + slug,
        method: 'GET'
    } ).done( ( response ) => {
      if(typeof response[0] === "undefined") {
        this.setState({
          isLoading:false,
          title: "404: Page Not Found",
          content: ""
        });
      }
      else {
        this.setState({
          isLoading:false,
          title: response[0].title.rendered,
          content: response[0].content.rendered
        });
      }
    })
  }

  render() {
    if(this.state.isLoading) return(<Loading/>);

    return(
      <Row className="wp-page">
        <Column small={12}>
          { !this.props.hideTitle &&
          <h1 dangerouslySetInnerHTML={{__html: this.state.title}} />
          }
          <div dangerouslySetInnerHTML={{__html: this.state.content}} />
        </Column>
      </Row>
    );
  }

}

export default WpPage;
