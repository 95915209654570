import $ from "jquery";

export const PRACTICAL_TEST_RESET         = 'PRACTICAL_TEST_RESET';

export const PRACTICAL_SCHEDULE_FETCH_START   = 'PRACTICAL_SCHEDULE_FETCH_START';
export const PRACTICAL_SCHEDULE_FETCH_SUCCESS = 'PRACTICAL_SCHEDULE_FETCH_SUCCESS';
export const PRACTICAL_SCHEDULE_FETCH_FAILURE = 'PRACTICAL_SCHEDULE_FETCH_FAILURE';

export const SCHEDULED_PRACTICALS_FETCH_START   = 'SCHEDULED_PRACTICALS_FETCH_START';
export const SCHEDULED_PRACTICALS_FETCH_SUCCESS = 'SCHEDULED_PRACTICALS_FETCH_SUCCESS';
export const SCHEDULED_PRACTICALS_FETCH_FAILURE = 'SCHEDULED_PRACTICALS_FETCH_FAILURE';

export const PRACTICAL_TEST_FETCH_START   = 'PRACTICAL_TEST_FETCH_START';
export const PRACTICAL_TEST_FETCH_SUCCESS = 'PRACTICAL_TEST_FETCH_SUCCESS';
export const PRACTICAL_TEST_FETCH_FAILURE = 'PRACTICAL_TEST_FETCH_FAILURE';

export const PRACTICAL_TEST_GRADE_START   = 'PRACTICAL_TEST_GRADE_START';
export const PRACTICAL_TEST_GRADE_SUCCESS = 'PRACTICAL_TEST_GRADE_SUCCESS';
export const PRACTICAL_TEST_GRADE_FAILURE = 'PRACTICAL_TEST_GRADE_FAILURE';

export const SCHEDULE_PRACTICAL_START   = 'SCHEDULE_PRACTICAL_START';
export const SCHEDULE_PRACTICAL_SUCCESS = 'SCHEDULE_PRACTICAL_SUCCESS';
export const SCHEDULE_PRACTICAL_FAILURE = 'SCHEDULE_PRACTICAL_FAILURE'

export const MARK_CUSTOM_PREREQ_COMPLETE = 'MARK_CUSTOM_PREREQ_COMPLETE';

export const fetchPracticalTestSessionAvailability = () => {
  return dispatch => {
    dispatch({ 
      type: PRACTICAL_SCHEDULE_FETCH_START
    });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/practical-sessions/schedule',
          // + '?_wpnonce=' + window.WP_API_Settings.nonce
        method: 'GET'
    }).done( ( response ) => {
      dispatch({ 
        type: PRACTICAL_SCHEDULE_FETCH_SUCCESS,
        data: response.data
      });
    }).fail(()=> {
       dispatch({ 
        type: PRACTICAL_SCHEDULE_FETCH_FAILURE,
        data: 'Unkown Error'
      });     
    });
  }
}

export const fetchScheduledPracticals = () => {
  return dispatch => {
    dispatch({ 
      type: SCHEDULED_PRACTICALS_FETCH_START
    });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/practical-sessions'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    } ).done( ( response ) => {
      dispatch({
        type:  SCHEDULED_PRACTICALS_FETCH_SUCCESS,
        data: response.data
      });
    } ).fail(()=> {
   
    });
  }
}

export const  fetchPracticalTest = (id) => {
  return dispatch => {
      dispatch({
        type:  PRACTICAL_TEST_FETCH_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/practical/' + id
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'GET'
    }).done( ( response ) => {

      dispatch({
        type:  PRACTICAL_TEST_FETCH_SUCCESS,
        data: response.data
      });

    } ).fail(()=> {
      // dispatch({
      //   type:  PRACTICAL_TEST_FETCH_FAILURE,
      //   error: "Could not find course"
      // });
    });
  }
}

export const  gradePracticalTest = (id, answers, notes) => {
  return dispatch => {

      dispatch({
        type:  PRACTICAL_TEST_GRADE_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/practical/grade' + id
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'POST',
        data: {
          answers: answers,
          notes: notes
        }
    }).done( ( response ) => {
      console.log(response.data);

      dispatch({
        type:  PRACTICAL_TEST_GRADE_SUCCESS,
        data: response.data
      });

      // dispatch(fetchScheduledPracticals());

    } ).fail(()=> {

      // dispatch({
      //   type:  PRACTICAL_TEST_GRADE_FAILURE,
      //   error: "There was an error while grading the course."
      // });

    });
  }
}

export const  scheduleLevel7Practical = (date) => {
  return dispatch => {

      dispatch({
        type:  SCHEDULE_PRACTICAL_START,
      });

     $.ajax( {
        url:  window.WP_API_Settings.endpoint 
          + 'usasf-api/v2/practical/schedule'
          + '?_wpnonce=' + window.WP_API_Settings.nonce,
        method: 'POST',
        data: {
          date: date,
        }
    }).done( ( response ) => {
      console.log(response.data);

      dispatch({
        type:  SCHEDULE_PRACTICAL_SUCCESS,
        data: response.data
      });

      // dispatch(fetchScheduledPracticals());

    } ).fail(()=> {
      dispatch({
        type:  SCHEDULE_PRACTICAL_FAILURE,
      });

      // dispatch({
      //   type:  PRACTICAL_TEST_GRADE_FAILURE,
      //   error: "There was an error while grading the course."
      // });

    });
  }
}

