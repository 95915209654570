import React from 'react';
import {
  getNextStep, 
    getHighestLevelCompleted,
    getCoursePercentageCompletion,
    startNextReq,
    getNextReq,
    startCourse
} from "../../util/credentials";

class NextStepText extends React.Component {
  constructor(props) {
    super(props);

    if(this.props.req) {
      this.state = {nextReq: this.props.req};
    }
    else {
      var nextReq = getNextReq(this.props.course);
      this.state = {nextReq: nextReq};
    }

  }

  getNextReqLevel = () => {
    var nextReq = getNextReq(this.props.course);
    var name = nextReq['name'];
    // return last character
    return name.substring(name.length - 1, name.length);
  }

  render () {


    if(typeof this.props.course != 'undefined' && this.props.course.name == "Cheer Building Level 7") {
      var courseName = this.state.nextReq.name;
      if(!this.props.req || this.props.req.type == 'written') {
        courseName = courseName.substring(18, courseName.length);
      }
      return(
        <span>{courseName}</span>
      )
    }
    else if(typeof this.state.nextReq !== "undefined" && this.state.nextReq.type == 'written') {
      var courseName = this.state.nextReq.name;
        console.log(courseName);
      if(courseName == 'Intro to Understanding Rules &amp; Glossary') {
        courseName = "Introduction";
      }
      else if(courseName.indexOf("Level") !== -1) {
        courseName = courseName.substring(8, courseName.length-10);
      }

      return (
       <span> Needs {courseName} Course</span>
      )
    }
    else if(typeof this.state.nextReq !== "undefined" && this.state.nextReq.type == 'practical') {
      return (<span>Needs Practical Session</span>)
    }
    else {
      return(<span><br/></span>);
    }
  }
}

export default NextStepText;
