import React from 'react';
import {Row, Column} from 'react-foundation';
import {connect} from 'react-redux';
import ProgressCard from '../progressCard';
import Loading from '../Loading';
import {calculateLevel7Status} from '../../util/credentials';

class ProgressCourses extends React.Component {

    getInProgressCourses = () => {
      var inProgressCourses = [];

      for(const property in this.props.credentials) {
        if(property === "building" || property === "tumbling") {
            if(this.props.credentials[property].in_progress && this.props.credentials[property].max_level == 0) {
              inProgressCourses.push(this.props.credentials[property]);
            }
        }
        else {
            if(this.props.credentials[property].in_progress && !this.props.credentials[property].is_credentialed) {
              inProgressCourses.push(this.props.credentials[property]);
            }
        }
      }

      // Level 7
      try {
        if(calculateLevel7Status(this.props.credentials)=='in_progress'){
          inProgressCourses.push({
            'name'            : "Cheer Building Level 7",
            'group'           : "Level 7",
            'is_credentialed' : false,
            'max_level'       : 7,
            'in_progress'     : true,
            'credentials'     : [this.props.credentials.building.credentials[6]]
          });
        }
      }
      catch(error){/*Silently fail*/};

      return inProgressCourses;
    }

    render () {
        if(this.getInProgressCourses().length == 0 && !this.props.isFetching)
          return(<span></span>);
        return (
          <div className="progress-courses">
             <Row className="label-content-gap">
                <Column small={12} className="line-wrap">
                  <span className="horizontal-line-text">In-Progress Courses</span>
                  <div className="horizontal-line-background"></div>
                </Column>
              </Row>
              <Row>
                { this.props.isFetching &&
                  <Loading/>
                }
                {!this.props.isFetching && this.getInProgressCourses().map( (course, index) => 
                  <ProgressCard course={course} key={index} />
                )}
              </Row>
          </div>
        )
    }
}

function mapStateToProps(state) {
  const {account} = state;
  return {
    credentials: account.credentials,
    isFetching: account.isFetching
  };
}

export default connect(mapStateToProps)(ProgressCourses);
