import React, { Component } from 'react';
import { Tabs, TabItem, TabsContent, TabPanel } from 'react-foundation';
import NextSteps from './nextSteps';
import InProgress from './inProgress';
import EADance from './eaDance';

export default class TabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      isMobile: false
    }
  }

  componentDidMount() {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }

  checkWidth = () => {
    if(window.innerWidth > 1024) {
      this.setState({isMobile: false});
    }
    else {
      this.setState({isMobile: true});
    }
  }

  getTabs = () => {
    var tabs = new Array();
    React.Children.map(this.props.children, (child: React.ReactElement<ChildPropTypes>) => {
      tabs.push({
        title: child.props.title,
        child: child
      });
    })
    return tabs;
  }

  makeTitleResponsive = (title) => {
    if(this.state.isMobile) {
      if(title.indexOf('History') !== -1)
        return 'History';
    }
    return title;
  }

  render() {
    return (
      <div className="progress-tabs">
        <Tabs>
          {this.getTabs().map( (tab, index) =>
            <TabItem isActive={this.state.activeIndex === index} onClick={(e) => { this.setState({ activeIndex: index }) }} key={index}>
              <a href="#" onClick={(e)=>{e.preventDefault();}} 
                className={ this.state.activeIndex != index && index < this.getTabs().length -1 && this.state.activeIndex != index+1 ? 'bordered' : '' }
              >
                <strong>
                  {this.makeTitleResponsive(tab.title)}
                </strong>
              </a>
            </TabItem>
          )}
        </Tabs>

        <TabsContent>
          {this.getTabs().map( (tab, index) =>
            <TabPanel isActive={this.state.activeIndex === index} key={index}>
              {tab.child}
            </TabPanel>
          )}
        </TabsContent>
      </div>
    )
  }
}

