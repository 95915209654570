import React from 'react'
import {Row, Column} from 'react-foundation';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import WpPage from './WpPage';

class Instructions extends React.Component {
  constructor(props) {
    super(props);

    let isMinimized = localStorage.getItem('isMinimized') == 'true';
    this.state = {
      isMinimized: isMinimized,
    };
  }

  handleClick = (e) => {
    e.preventDefault();
    localStorage.setItem('isMinimized', !this.state.isMinimized);
    this.setState({isMinimized:!this.state.isMinimized});
  }

  render(){
    return(
      <div className="instructions">
       <Row >
          <Column small={12} className="line-wrap">
            <span className="horizontal-line-text">Overview of the Credentialing Process</span>
            <div className="horizontal-line-background"></div>
          </Column>
        </Row>
        <Row>
          <Column small={12} className="line-wrap">
            <div className="card">
              <div className={this.state.isMinimized ? 'instruction-minimized' : 'instuction-maxamized'}>
                <WpPage {...this.props} hideTitle={true}/>
              </div>
              <div>
                <a href="#" onClick={this.handleClick} className="expand">
                  <>
                  {this.state.isMinimized &&
                    <span>Expand Section <FaChevronDown/></span>
                  }
                  {!this.state.isMinimized &&
                    <span>Minimize Section <FaChevronUp/></span>
                  }
                  </>
                </a>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    )
  }

}

export default Instructions;
