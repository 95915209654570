import {
  WRITTEN_TEST_FETCH_START,
  WRITTEN_TEST_FETCH_SUCCESS,
  WRITTEN_TEST_FETCH_FAILURE,
  WRITTEN_TEST_GRADE_START,
  WRITTEN_TEST_GRADE_SUCCESS,
  WRITTEN_TEST_GRADE_FAILURE,
  WRITTEN_TEST_SAVE_PROGRESS_START,
  WRITTEN_TEST_SAVE_PROGRESS_SUCCESS,
  WRITTEN_TEST_SAVE_PROGRESS_FAILURE,
  WRITTEN_TEST_RESET
} from "../actions/writtenTest";

export const initialState = {
  isFetching: true,
  isGrading: false,
  isSaving: false,
  grade: {
    grade: 0,
    incorrectAnswers: []
  }
};


export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case WRITTEN_TEST_RESET: {
      return {
        ...state,
        isFetching: false,
        isGrading: false,
        grade: initialState.grade,
        error: null
      }
    }
    case WRITTEN_TEST_FETCH_START: {
      return {
        ...state,
        isFetching: true,
        isGrading: false,
        error: null
      }
    }
    case WRITTEN_TEST_FETCH_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        ...action.data
      }
    }
    case WRITTEN_TEST_FETCH_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    case WRITTEN_TEST_GRADE_START: {
      return {
        ...state,
        isGrading: true,
        error: null
      }
    }
    case WRITTEN_TEST_GRADE_SUCCESS: {
      return {
        ...state,
        isGrading: false,
        grade: action.grade,
      }
    }
    case WRITTEN_TEST_GRADE_FAILURE: {
      return {
        ...state,
        isGrading: false,
        error: action.error
      }
    }
    case WRITTEN_TEST_SAVE_PROGRESS_START: {
      return {
        ...state,
        isSaving: true,
        error: null
      }
    }
    case WRITTEN_TEST_SAVE_PROGRESS_SUCCESS: {
      return {
        ...state,
        isGrading: false,
      }
    }
    case WRITTEN_TEST_SAVE_PROGRESS_FAILURE: {
      return {
        ...state,
        isGrading: false,
        savingError: action.error
      }
    }
    default:
      return state;
  }
}

